import { Language } from "../constants/language"
import { getIsHorsle, getIsLazy, getIsScalizzi, getIsShowSpotleBot, getIsSpeedy, getIsStaircase, getIsTaytay, getIsUnlimitedMode, isCoSpotle, isCustom, isGinJanner, isImpossible, isSixLetter } from "../App"
import { getToday } from "./dateutils"
import { CharStatus } from "./statuses"
import { getNewGameDate } from "./words"
import { recalcDefinitionAPI } from "../components/navbar/Dictionary"
import { createStats } from "../bot/SpotleBot"
import { TranslationKey } from "../constants/strings"
import { getTranslation } from "../context/messages"
import { MAX_CHALLENGES } from "../constants/settings"
import { decrypt } from "./encryption"
import { BOTS_INFO, BOT_NAME } from "../constants/bots"
import { winsNeededForNextLevel } from "./cospotle"
import { isSpeedyGameDone } from "./speedy"
import { addDays } from "date-fns"

const gameStateKey = 'gameState'
const highContrastKey = 'highContrast'
const unlimitedKey = 'unlimited'
const gameDateKey = 'gameDate'
const statusesKey = 'statuses'
const coSpotleStatusesKey = 'coSpotleStatuses'
const charObjKey = 'charObj'
const gameStatKey = 'gameStats'
const gameGridModeKey = 'gameGridMode'
const gameHiddenLetterKey = 'gameHiddenLetter'
const gameModeKey = 'gameMode'
const cookiesModeKey = 'cookiesMode'
const countryKey = 'country'
const titleKey = 'title'
const languageKey = 'language'
const definitionsKey = 'definitions'
const themeKey = 'theme'
const botStatsKey = 'botStats'
const playerBotStatsKey = 'playerBotStats'
const gameBotAssistedModeKey = 'gameBotAssistedMode'
const gameRandomModeKey = 'gameRandomMode'
const gameSpyfallModeKey = 'gameSpyfallMode'
const counterKey = 'counter'
const winsNeededKey = 'winsNeeded'
const speedyStartTimeKey = 'speedyStartTime'
const speedyEndTimeKey = 'speedyEndTime'
const gameStreakKey = 'gameStreak'
const numberOfGamesKey = 'numberOfGames'
const resultsKey = 'results'
const numberOfRowsKey = 'numberOfRows'
const usernameKey = 'username'
const myTurnKey = 'myTurn'
const opponentKey = 'opponent'
const opponentLevelKey = 'opponentLevel'
const botModeKey = 'botMode'
const botChallengeModeKey = 'botChallengeMode'
const customUsernameKey = 'customUsername'
const guessDateKey = 'guessDate'
const teamModeKey = 'teamMode'
const parallelModeKey = 'parallelMode'
const opponentCountryKey = 'opponentCountry'
const coSpotleCustomCodeKey = 'coSpotleCustomGame'
const copiedKey = 'copied'
const customCodeKey = 'customGame'
const customSplitTimeKey = 'customSplitTime'
const newGameModeKey = 'newGameMode1'
const announcementsKey = 'announcements'
const botKey = 'bot'
const remainingSolutionsKey = 'remainingSolutions'
const indexKey = 'index'
const outsideBotKey = 'outsideBot'
const aprilFoolsSolutions = 'aprilFoolsSolutions'
const shownAprilFoolsKey = 'showsAprilFools'
const globalGuessesKey = 'globalGuesses'
const allGlobalGuessesKey = 'allGlobalGuesses'
const wordCountsKey = 'wordCounts'

export type StoredGameState = {
  guesses: string[]
  solution: string
}

function createSimpleKey(key: string):string{
  var newKey = getLanguageText()

  if(getIsLazy()){
    newKey = newKey + "lazy"
  }

  if(getIsSpeedy()){
    newKey = newKey + "speedy"
  }
  
  if(isImpossible()){
    newKey = newKey + "impossible"
  }

  if(isCoSpotle()){
    newKey = newKey + "coSpotle"
  }

  if(isSixLetter()){
    newKey = newKey + "six"
  }

  if(newKey === ""){
    return key
  }
  
  //the upperCase is need for legacy purposes
  return newKey + key.charAt(0).toUpperCase() + key.slice(1);
}

export function createKey(key: string, checkOutsideBot?:boolean):string {
  var newKey = getLanguageText()

  if(getIsHorsle()){
    newKey = newKey + "dummy"
  }

  if(getIsScalizzi()){
    newKey = newKey + "scali"
  }

  if(checkOutsideBot !== true && getIsShowSpotleBot()){
    newKey = newKey + "bot"
  }

  if(getIsUnlimitedMode()){
    newKey = newKey + "unlimited"
  }

  if(getIsStaircase()){
    newKey = newKey + "staircase"
  }

  if(getIsLazy()){
    newKey = newKey + "lazy"
  }

  if(getIsTaytay()){
    newKey = newKey + "taytay"
  }

  if(getIsSpeedy()){
    newKey = newKey + "speedy"
    newKey = newKey + getStoredNumberOfGames()
  }

  if(isImpossible()){
    newKey = newKey + "impossible"
    newKey = newKey + getStoredNumberOfRows()
  }

  if(isCoSpotle()){
    newKey = newKey + 'coSpotle'

    if(getStoredBotMode()){
      newKey = newKey + 'bot'
      
      if(getStoredBotChallengeMode()){
        newKey = newKey + 'challenge'
      }
    }

    if(getStoredTeamMode()){
      newKey = newKey + 'team'
    }

    if(getStoredParallelMode()){
      newKey = newKey + 'parallel'
    }
  }

  if(isCustom()){
    newKey = newKey + 'custom'
  }

  if(isGinJanner()){
    newKey = newKey + 'ginjanner'
  }

  if(isSixLetter()){
    newKey = newKey + 'six'
  } 
  
  if(newKey === ""){
    return key
  }
  
  //the upperCase is need for legacy purposes
  return newKey + key.charAt(0).toUpperCase() + key.slice(1);
}

export const saveGameStateToLocalStorage = (
  gameState: StoredGameState, cospotle: boolean
) => {
  var key = createKey(gameStateKey)

  if(cospotle){
    key = key + '1'
  }

  localStorage.setItem(key, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = (cospotle: boolean) => {
  var key = createKey(gameStateKey)

  if(cospotle){
    key = key + '1'
  }

  const state = localStorage.getItem(key)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
  timeoutWins: number
  sixPlusWins: number
  ties: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(createKey(gameStatKey), JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  var stats = localStorage.getItem(createKey(gameStatKey))
  return stats ? (JSON.parse(stats) as GameStats) : null
}

export const setHasSeenNewGameModeInfo = (newGameMode: boolean) => {
  if (newGameMode) {
    localStorage.setItem(newGameModeKey, '3')
  } else {
    localStorage.removeItem(newGameModeKey)
  }
}

export const getHasSeenNewGameModeInfo = () => {
  return localStorage.getItem(newGameModeKey) === '3'
}

export const setHasSeenAnnouncements = (seen: boolean) => {
  if (seen) {
    localStorage.setItem(announcementsKey, '2')
  } else {
    localStorage.removeItem(announcementsKey)
  }
}

export const getHasSeenAnnouncements = () => {
  return localStorage.getItem(announcementsKey) === '2'
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
}

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey)
  return highContrast === '1'
}

export const setStoredIsUnlimitedMode = (isUnlimited: boolean) => {
  if (isUnlimited) {
    localStorage.setItem(createKey(unlimitedKey), '1')
  } else {
    localStorage.removeItem(createKey(unlimitedKey))
  }
}

export const setStoredStatuses = (statuses : Map<number, CharStatus[]>) => {
  const valuesArray = new Array(statuses.size)
  var i = 0;
  for (let entry of Array.from(statuses.entries())) {
    valuesArray[i] = entry[1]
    i++
  }

  localStorage.removeItem(createKey(statusesKey))
  localStorage.setItem(createKey(statusesKey), JSON.stringify(valuesArray))
}

export const getStoredStatuses = () => {
  var state = localStorage.getItem(createKey(statusesKey))
  
  const statuses = new Map<number, CharStatus[]>()
  if(state){
    var i = 0
    for (let value of JSON.parse(state)) {
      statuses.set(i, value)
      i++
    }
  }

  return statuses
}

export const setStringStoredStatuses = (statuses: string) => {
  localStorage.removeItem(createKey(statusesKey))
  localStorage.setItem(createKey(statusesKey), statuses)
}

export const getStringStoredStatuses = () => {
  return localStorage.getItem(createKey(statusesKey))
}

export const setStoredWordCounts = (wordCounts : Map<number, string[]>, guessIndex: number) => {
  const valuesArray = new Array(wordCounts.size)
  var i = 0;
  for (let entry of Array.from(wordCounts.entries())) {
    valuesArray[i] = entry[0]
    i++
    valuesArray[i] = entry[1]
    i++
  }

  localStorage.removeItem(createKey(wordCountsKey + guessIndex))
  localStorage.setItem(createKey(wordCountsKey + guessIndex), JSON.stringify(valuesArray))
}

export const getStoredWordCounts = (guessIndex: number) => {
  var state = localStorage.getItem(createKey(wordCountsKey + guessIndex))
  
  const wordCounts = new Map<number, string[]>()
  if(state){
    const values = JSON.parse(state)
    for (let i = 0; i < values.length; i = i + 2) {
      wordCounts.set(values[i], values[i + 1])
    }
  }

  return wordCounts
}

export const setStoredCoSpotleStatuses = (statuses : Map<number, CharStatus[]>) => {
  const valuesArray = new Array(statuses.size)
  var i = 0;
  for (let entry of Array.from(statuses.entries())) {
    valuesArray[i] = entry[1]
    i++
  }

  localStorage.removeItem(createKey(coSpotleStatusesKey))
  localStorage.setItem(createKey(coSpotleStatusesKey), JSON.stringify(valuesArray))
}

export const getStoredCoSpotleStatuses = () => {
  var state = localStorage.getItem(createKey(coSpotleStatusesKey))
  
  const statuses = new Map<number, CharStatus[]>()
  if(state){
    var i = 0
    for (let value of JSON.parse(state)) {
      statuses.set(i, value)
      i++
    }
  }

  return statuses
}

export const setStoredCharObj = (charObj: { [key: string]: CharStatus }) => {
  localStorage.removeItem(createKey(charObjKey))
  localStorage.setItem(createKey(charObjKey), JSON.stringify(charObj))
}

export const getStoredCharObj = () => {
  const state = localStorage.getItem(createKey(charObjKey))
  return state ? (JSON.parse(state) as {[key: string]: CharStatus}) : {}
}

export const setStoredGameDate = (gameDate: Date, checkWithinBot?:boolean) => {
  if(getIsUnlimitedMode()){
    localStorage.removeItem(createKey(gameDateKey, checkWithinBot))
    localStorage.setItem(createKey(gameDateKey, checkWithinBot), gameDate.toString())
  }
}

export const getStoredGameDate = (checkWithinBot?:boolean) => {
  if(getIsUnlimitedMode()){
    const stringGameDate = localStorage.getItem(createKey(gameDateKey, checkWithinBot))
    if(stringGameDate === null || typeof stringGameDate === "undefined"){
      const gameDate = getNewGameDate(getIsUnlimitedMode())
      setStoredGameDate(gameDate, checkWithinBot)
      return gameDate
    }else{
      return new Date(stringGameDate!)
    }
  } else if (getIsSpeedy()){
    if(isSpeedyGameDone()){
      return addDays(getToday(), 10 * (getStoredNumberOfGames() - 1) * 7) //counter starts at 0
    }

    return addDays(getToday(), 10 * getCounter() * 7)
  }

  return getToday();
}

export const setStoredGridHardMode = (isHard: boolean) => {
  localStorage.setItem(createKey(gameGridModeKey), isHard ? 'hard' : 'normal')
}

export const getStoredGridHardMode = (checkOutsideBot?: boolean) => {
  return localStorage.getItem(createKey(gameGridModeKey, checkOutsideBot))
          ? localStorage.getItem(createKey(gameGridModeKey, checkOutsideBot)) === 'hard'
          : false
}

export const setStoredHiddenLetterMode = (isHard: boolean) => {
  localStorage.setItem(createKey(gameHiddenLetterKey), isHard ? 'hard' : 'normal')
}

export const getStoredHiddenLetterMode = (checkOutsideBot?: boolean) => {
  return localStorage.getItem(createKey(gameHiddenLetterKey, checkOutsideBot))
          ? localStorage.getItem(createKey(gameHiddenLetterKey, checkOutsideBot)) === 'hard'
          : false
}

export const setStoredHardMode = (isHard: boolean) => {
  localStorage.setItem(createKey(gameModeKey), isHard ? 'hard' : 'normal')
}

export const getStoredHardMode = (checkOutsideBot?: boolean) => {
  return localStorage.getItem(createKey(gameModeKey, checkOutsideBot))
          ? localStorage.getItem(createKey(gameModeKey, checkOutsideBot)) === 'hard'
          : false
}

export const setStoredBotAssistedMode = (isHard: boolean) => {
  localStorage.setItem(createKey(gameBotAssistedModeKey), isHard ? 'hard' : 'normal')
}

export const getStoredBotAssistedMode = (checkWithinBot?:boolean) => {
  return localStorage.getItem(createKey(gameBotAssistedModeKey, checkWithinBot))
          ? localStorage.getItem(createKey(gameBotAssistedModeKey, checkWithinBot)) === 'hard'
          : true // true since this is the default
}

export const setStoredRandomMode = (isHard: boolean) => {
  localStorage.setItem(createKey(gameRandomModeKey), isHard ? 'hard' : 'normal')
}

export const getStoredRandomMode = () => {
  return localStorage.getItem(createKey(gameRandomModeKey))
          ? localStorage.getItem(createKey(gameRandomModeKey)) === 'hard'
          : false
}

export const setStoredSpyfallMode = (isHard: boolean) => {
  localStorage.setItem(createKey(gameSpyfallModeKey), isHard ? 'hard' : 'normal')
}

export const getStoredSpyfallMode = () => {
  return localStorage.getItem(createKey(gameSpyfallModeKey))
          ? localStorage.getItem(createKey(gameSpyfallModeKey)) === 'hard'
          : false
}

export const setCookiesMode = (isAll: boolean) => {
  localStorage.setItem(cookiesModeKey, isAll ? 'all' : 'essential')
}

export const getCookiesMode = () => {
  //return localStorage.getItem(cookiesModeKey) === 'all'
  return true
}

export const isRussia = () => {
  return localStorage.getItem(countryKey) === 'Russia' ||
    localStorage.getItem(countryKey) === 'russia' ||
    localStorage.getItem(countryKey) === 'Russian Federation' ||
    localStorage.getItem(countryKey) === 'russian federation'
}

export const isCountrySet = () => {
  return localStorage.getItem(countryKey) !== null
}

export const setCountry = (country: string | null) => {
  if(country !== null){
    localStorage.setItem(countryKey, country)
  }
}

export const setTitle = (title: string) => {
  localStorage.setItem(titleKey, title)
}

export const getTitle = () => {
  const state = localStorage.getItem(titleKey)
  return state ? state : getTranslation(TranslationKey.SPOTLE)
}

export const setLanguage = (language: Language) => {
  localStorage.setItem(languageKey, language)
}

export const setLanguageText = (language: string) => {
  var typedLanguageString = language as keyof typeof Language;
  localStorage.setItem(languageKey, Language[typedLanguageString])
}

export const getLanguage = () => {
  const indexOfS = Object.values(Language).indexOf(getLanguageText() as unknown as Language);
  return Language[Object.keys(Language)[indexOfS] as keyof typeof Language];
}

export const getLanguageText = () => {
  let letLanguageString = localStorage.getItem(languageKey);
  if(letLanguageString === null 
    || typeof letLanguageString === "undefined"
    || letLanguageString === ""){
    setLanguage(Language.ENGLISH)
  }

  return localStorage.getItem(languageKey);
}

export const getLanguageKey = () => {
  const indexOfS = Object.values(Language).indexOf(getLanguageText() as unknown as Language);
  return Object.keys(Language)[indexOfS] as keyof typeof Language
}

export const setDefinitions = (definitions: string[]) => {
  localStorage.setItem(createSimpleKey(definitionsKey), JSON.stringify(definitions))
}

export const getDefinitions = (solution: string) => {
  let definition = localStorage.getItem(createSimpleKey(definitionsKey));
  if(definition === null 
    || typeof definition === "undefined"
    || definition === ""){
      recalcDefinitionAPI(solution);
  }
  
  return definition ? (JSON.parse(definition) as string[]) : []
}

export const setStoredDarkMode = (darkMode: boolean) => {
  localStorage.setItem(themeKey, darkMode ? 'dark' : 'light')
}

export const getStoredDarkMode = () => {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  let darkMode = localStorage.getItem(themeKey)
  if(darkMode === null 
    || typeof darkMode === "undefined"
    || darkMode === ""){
      localStorage.setItem(themeKey, prefersDarkMode ? 'dark' : 'light')
    }

  return localStorage.getItem(themeKey) === 'dark';
}

export const setStoredBotStats = (botStats : string[][], playerStats: boolean) => {
  var key:string = botStatsKey
  if(playerStats){
    key = playerBotStatsKey
  }

  localStorage.removeItem(createKey(key))
  localStorage.setItem(createKey(key), JSON.stringify(botStats))
}

export const getStoredBotStats = (playerStats: boolean, guessesLength: number) => {
  var key:string = botStatsKey
  if(playerStats){
    key = playerBotStatsKey
  }

  const state = localStorage.getItem(createKey(key))
  return state ? (JSON.parse(state) as string[][]) : createStats(guessesLength)
}

export const getCounter = () => {
  const state = localStorage.getItem(createKey(counterKey))

  if(state === null){
    setCounter(0)
    return 0
  }
  return +state
}

export const setCounter = (counter: number) => {
  localStorage.removeItem(createKey(counterKey))
  localStorage.setItem(createKey(counterKey), counter.toString())
}

export function incCounter() {
  setCounter(getCounter() + 1)
}

export function decCounter() {
  setCounter(getCounter() - 1)
}

export const getWinsNeeded = () => {
  const state = localStorage.getItem(createKey(winsNeededKey))

  if(state === null){
    setWinsNeeded(winsNeededForNextLevel())
    return winsNeededForNextLevel()
  }
  return +state
}

export const setWinsNeeded = (wins: number) => {
  localStorage.removeItem(createKey(winsNeededKey))
  localStorage.setItem(createKey(winsNeededKey), wins.toString())
}

export const setSpeedyStartTime = (startTime: number) => {
  localStorage.removeItem(createKey(speedyStartTimeKey))
  localStorage.setItem(createKey(speedyStartTimeKey), JSON.stringify(startTime))
}

export const getSpeedyStartTime = () => {
  const state = localStorage.getItem(createKey(speedyStartTimeKey))

  if(state === null){
    setSpeedyStartTime(0)
    return 0
  }

  return +state
}

export const setSpeedyEndTime = (endTime: number) => {
  localStorage.removeItem(createKey(speedyEndTimeKey))
  localStorage.setItem(createKey(speedyEndTimeKey), JSON.stringify(endTime))
}

export const getSpeedyEndTime = () => {
  const state = localStorage.getItem(createKey(speedyEndTimeKey))

  if(state === null || state === '0'){
    const newTime = Math.floor(Date.now() / 1000)
    setSpeedyEndTime(newTime)

    return newTime
  }

  return +state
}

export const setGameStreak = (gameStreak: number[]) => {
  localStorage.removeItem(createKey(gameStreakKey))
  localStorage.setItem(createKey(gameStreakKey), JSON.stringify(gameStreak))
}

export const getGameStreak = () => {
  const state = localStorage.getItem(createKey(gameStreakKey))
  return state ? (JSON.parse(state) as number[]) : []
}

export const addGameStreakWin = (guesses: number) => {
  setGameStreak([...getGameStreak(), guesses])
}
  
export const addGameStreakLoss = () => {
  setGameStreak([...getGameStreak(), -1])
}

export const setStoredNumberOfGames = (gameStreak: number) => {
  localStorage.removeItem(createSimpleKey(numberOfGamesKey))
  localStorage.setItem(createSimpleKey(numberOfGamesKey), JSON.stringify(gameStreak))
}

export const getStoredNumberOfGames = () => {
  if(getIsSpeedy() && getIsLazy() && !getIsUnlimitedMode()){
    return 10
  } else if (getIsSpeedy() && !getIsUnlimitedMode()){
    return 5
  }
  
  const state = localStorage.getItem(createSimpleKey(numberOfGamesKey))
  if(state){
    return (JSON.parse(state) as number)
  } else if(getStoredBotChallengeMode()){
    return 3
  }
  
  return 25
}

export const setResults = (results: number[]) => {
  localStorage.removeItem(createKey(resultsKey))
  localStorage.setItem(createKey(resultsKey), JSON.stringify(results))
}

export const getResults = () => {
  const state = localStorage.getItem(createKey(resultsKey))
  return state ? (JSON.parse(state) as number[]) : []
}

export const setStoredNumberOfRows = (rows: number) => {
  localStorage.removeItem(createSimpleKey(numberOfRowsKey))
  localStorage.setItem(createSimpleKey(numberOfRowsKey), JSON.stringify(rows))
}

export const getStoredNumberOfRows = () => {
  const state = localStorage.getItem(createSimpleKey(numberOfRowsKey))
  return state ? (JSON.parse(state) as number) : 1
}

export const setStoredUsername = (username: string) => {
  localStorage.removeItem(createKey(usernameKey))
  localStorage.setItem(createKey(usernameKey), username)
}

export const getStoredUsername = () => {
  const state = localStorage.getItem(createKey(usernameKey))
  if(state === null || state === undefined){
    var newUsername = Date.now().toString()
    newUsername = newUsername + Math.floor(100000000000 + Math.random() * 900000000000).toString() + getStoredCustomUsername()
    setStoredUsername(newUsername)
    return newUsername
  }

  return state
}

export const setStoredMyTurn = (myTurn: boolean) => {
  localStorage.removeItem(createKey(myTurnKey))
  localStorage.setItem(createKey(myTurnKey), JSON.stringify(myTurn))
}

export const getStoredMyTurn = () => {
  const state = localStorage.getItem(createKey(myTurnKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredOpponent = (opponent: string) => {
  localStorage.removeItem(createKey(opponentKey))
  localStorage.setItem(createKey(opponentKey), opponent)
}

export const getStoredOpponent = () => {
  const state = localStorage.getItem(createKey(opponentKey))
  return state ? state : ''
}

export const setStoredOpponentLevel = (level: number) => {
  if(level < 1){
    level = 1
  } else if (level > 10){
    level = 10
  }

  localStorage.removeItem(createKey(opponentLevelKey))
  localStorage.setItem(createKey(opponentLevelKey), level.toString())
}

export const getStoredOpponentLevel = () => {
  const state = localStorage.getItem(createKey(opponentLevelKey))
  return state ? +state : 1
}

export const setBot = (bot: BOT_NAME) => {
  localStorage.setItem(createKey(botKey), bot)
}

export const getBot = () => {
  const botStr = localStorage.getItem(createKey(botKey))
  var bot = BOT_NAME.SPOTLE
  if(botStr !== null && typeof botStr !== "undefined" && botStr !== ""){
    bot = botStr as BOT_NAME
  } else {
    setBot(BOT_NAME.SPOTLE)
  }

  return BOTS_INFO[bot];
}

export const setStoredBotMode = (botMode: boolean) => {
  localStorage.removeItem(createSimpleKey(botModeKey))
  localStorage.setItem(createSimpleKey(botModeKey), JSON.stringify(botMode))
}

export const getStoredBotMode = () => {
  const state = localStorage.getItem(createSimpleKey(botModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredBotChallengeMode = (botChallengeMode: boolean) => {
  localStorage.removeItem(createSimpleKey(botChallengeModeKey))
  localStorage.setItem(createSimpleKey(botChallengeModeKey), JSON.stringify(botChallengeMode))
}

export const getStoredBotChallengeMode = () => {
  const state = localStorage.getItem(createSimpleKey(botChallengeModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const getStoredPlainCoSpotle = () => {
  return !getStoredTeamMode() && !getStoredParallelMode()
}

export const setStoredCustomUsername = (customUsername: string) => {
  localStorage.removeItem(createSimpleKey(customUsernameKey))
  localStorage.setItem(createSimpleKey(customUsernameKey), customUsername)
}

export const getStoredCustomUsername = () => {
  const state = localStorage.getItem(createSimpleKey(customUsernameKey))
  return state ? state : ''
}

export const setStoredGuessDate = (gameDate: Date | undefined) => {
  localStorage.removeItem(createKey(guessDateKey))

  if(gameDate !== undefined){
    localStorage.setItem(createKey(guessDateKey), gameDate.toString())
  }
}

export const getStoredGuessDate = () => {
  const stringGameDate = localStorage.getItem(createKey(guessDateKey))
  if(stringGameDate === null || typeof stringGameDate === "undefined"){
    return undefined
  }else{
    return new Date(stringGameDate!)
  }
}

export const setStoredTeamMode = (teamMode: boolean) => {
  localStorage.removeItem(createSimpleKey(teamModeKey))
  localStorage.setItem(createSimpleKey(teamModeKey), JSON.stringify(teamMode))
}

export const getStoredTeamMode = () => {
  const state = localStorage.getItem(createSimpleKey(teamModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredParallelMode = (teamMode: boolean) => {
  localStorage.removeItem(createSimpleKey(parallelModeKey))
  localStorage.setItem(createSimpleKey(parallelModeKey), JSON.stringify(teamMode))
}

export const getStoredParallelMode = () => {
  const state = localStorage.getItem(createSimpleKey(parallelModeKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredOpponentCountry = (country: string) => {
  localStorage.removeItem(createKey(opponentCountryKey))
  localStorage.setItem(createKey(opponentCountryKey), country)
}

export const getStoredOpponentCountry = () => {
  const state = localStorage.getItem(createKey(opponentCountryKey))
  return state ? state : ''
}

export const deleteStoredCustomCode = () => {
  localStorage.removeItem(createKey(coSpotleCustomCodeKey))
}

export const handleStoredCoSpotleCustomCode = (customGame: string) => {
  if(customGame.includes('/')){
    const urlArray = customGame.split('/')
    customGame = urlArray[urlArray.length - 1]

    if(!customGame.includes('&')){
      return false
    }

    const gameArray = customGame.split('&')
    if(gameArray[0] === '1'){
      setStoredHardMode(true)
    }else{
      setStoredHardMode(false)
    }
    if(gameArray[1] === '1'){
      setStoredGridHardMode(true)
    }else{
      setStoredGridHardMode(false)
    }
    if(gameArray[2] === '1'){
      setStoredHiddenLetterMode(true)
    }else{
      setStoredHiddenLetterMode(false)
    }

    handleStoredCoSpotleCustomCode(gameArray[3])
  }
  
  if(getStoredCoSpotleCustomCode() === customGame) {
    return false
  }

  localStorage.removeItem(createKey(coSpotleCustomCodeKey))
  localStorage.setItem(createKey(coSpotleCustomCodeKey), customGame)

  return true
}

export const getStoredCoSpotleCustomCode = () => {
  const state = localStorage.getItem(createKey(coSpotleCustomCodeKey))
  return state ? state : null
}

export const setStoredCopiedMode = (copied: boolean) => {
  localStorage.removeItem(createSimpleKey(copiedKey))
  localStorage.setItem(createSimpleKey(copiedKey), JSON.stringify(copied))
}

export const getStoredCopiedMode = () => {
  const state = localStorage.getItem(createSimpleKey(copiedKey))
  return state ? (JSON.parse(state) as boolean) : false
}

export const setStoredCustomInfo = (customGame: string) => {
  const urlArray = customGame.split('/&/')
  customGame = urlArray[urlArray.length - 1]
  
  if(customGame.endsWith('/custom')){
    return
  }

  if(!customGame.includes('&')){
    localStorage.removeItem(createKey(customCodeKey))
    localStorage.setItem(createKey(customCodeKey), customGame)

    return
  }

  const gameArray = customGame.split('&')
  const solution:string = decrypt(gameArray[0])!
  const guesses:string[] = []
  const statuses = new Map<number, CharStatus[]>()
  var binary = parseInt(gameArray[1], 10).toString(2)

  while(binary.length < MAX_CHALLENGES * solution.length){
    binary = '0' + binary
  }

  localStorage.removeItem(createKey(customCodeKey))
  localStorage.setItem(createKey(customCodeKey), solution)

  for(var i = 0; i < MAX_CHALLENGES; i++){
    for(var j = 0; j < solution.length; j++){
      if(statuses.get(i) === undefined){
        statuses.set(i, Array(solution.length))
      }

      if(binary.charAt(j + i * (solution.length)) === '1'){
        statuses.get(i)![j] = 'hidden'
      }
    }
  }
  
  setStoredCustomSplitTime(binary)
  setStoredStatuses(statuses)
  setStoredCharObj({})
  saveGameStateToLocalStorage({ guesses, solution }, false)
}


export const getStoredCustomSolution = () => {
  const state = localStorage.getItem(createKey(customCodeKey))
  return state ? state : ''
}

export const setStoredCustomSplitTime = (splitTime: string) => {
  const array = []

  for(var i = 0; i < splitTime.length; i++){
    array[i] = +splitTime.charAt(i)
  }

  localStorage.removeItem(createKey(customSplitTimeKey))
  localStorage.setItem(createKey(customSplitTimeKey), JSON.stringify(array))
}

export const getStoredCustomSplitTime = () => {
  const state = localStorage.getItem(createKey(customSplitTimeKey))
  return state ? (JSON.parse(state) as number[]) : []
}

export const setStoredRemainingSolutions = (index: number, remaining: string[], outsideBot: boolean) => {
  setStoredOutsideBot(outsideBot)

  localStorage.removeItem(createKey(remainingSolutionsKey + index.toString(), getStoredOutsideBot()))
  localStorage.setItem(createKey(remainingSolutionsKey + index.toString(), getStoredOutsideBot()), JSON.stringify(remaining.sort()))
}

export const getStoredRemainingSolutions = (index: number) => {
  const state = localStorage.getItem(createKey(remainingSolutionsKey + index.toString(), getStoredOutsideBot()))
  return state ? (JSON.parse(state) as string[]) : []
}

//for the modal
export const setStoredCurrentRemainingSolutions = (remaining: string[]) => {
  localStorage.removeItem(createKey(remainingSolutionsKey))
  localStorage.setItem(createKey(remainingSolutionsKey), JSON.stringify(remaining))
}

//for the modal
export const getStoredCurrentRemainingSolutions = () => {
  const state = localStorage.getItem(createKey(remainingSolutionsKey))
  return state ? (JSON.parse(state) as string[]) : []
}

const getStoredOutsideBot = () => {
  const outsideBot = localStorage.getItem(outsideBotKey)
  return outsideBot === '1'
}

export const setStoredOutsideBot = (outsideBot: boolean) => {
  if (outsideBot) {
    localStorage.setItem(outsideBotKey, '1')
  } else {
    localStorage.removeItem(outsideBotKey)
  }
}

export const setStoredIndex = (index: number) => {
  localStorage.removeItem(createKey(indexKey))
  localStorage.setItem(createKey(indexKey), JSON.stringify(index))
}

export const getStoredIndex = () => {
  const state = localStorage.getItem(createKey(indexKey))
  return state ?  +state : 0
}

export const setStoredAprilFoolsSolutions = (remaining: string) => {
  const total = [...getStoredAprilFoolsSolutions(), remaining]
  
  localStorage.removeItem(createKey(aprilFoolsSolutions))
  localStorage.setItem(createKey(aprilFoolsSolutions), JSON.stringify(total))
}

export const getStoredAprilFoolsSolutions = () => {
  const state = localStorage.getItem(createKey(aprilFoolsSolutions))
  return state ? (JSON.parse(state) as string[]) : []
}

export const resetStoredAprilFoolsSolutions = () => {
  localStorage.removeItem(createKey(aprilFoolsSolutions))
}

export const getStoredShownAprilFools = () => {
  return localStorage.getItem(shownAprilFoolsKey) === '1'
}

export const setStoredShownAprilFools = (shown: boolean) => {
  if (shown) {
    localStorage.setItem(shownAprilFoolsKey, '1')
  } else {
    localStorage.removeItem(shownAprilFoolsKey)
  }
}

// function replacer(key: any, value: any) {
//   if(value instanceof Map) {
//     return {
//       dataType: 'Map',
//       value: Array.from(value.entries()), // or with spread: value: [...value]
//     };
//   } else {
//     return value;
//   }
// }

function reviver(key: any, value: any) {
  if(typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

interface Task {
  global: string
}

export const setStoredGlobalGuesses = (map: any, guessIndex: number) => {
  const data:Task = JSON.parse(JSON.stringify(map))[0]

  localStorage.removeItem(globalGuessesKey + guessIndex)
  localStorage.setItem(globalGuessesKey + guessIndex, data.global)
}

export const getStoredGlobalGuesses = (guessIndex: number) => {
  const state = localStorage.getItem(globalGuessesKey + guessIndex)
  if (state === null || state === undefined) {
    return new Map()
  }
  
  const map: Map<string, number> = JSON.parse(state, reviver)

  return map
}

export const setStoredAllGlobalGuesses = (map: any, guessIndex: number) => {
  const data:Task = JSON.parse(JSON.stringify(map))[0]

  localStorage.removeItem(allGlobalGuessesKey + guessIndex)
  localStorage.setItem(allGlobalGuessesKey + guessIndex, data.global)
}

export const getStoredAllGlobalGuesses = (guessIndex: number) => {
  const state = localStorage.getItem(allGlobalGuessesKey + guessIndex)
  if (state === null || state === undefined) {
    return new Map()
  }
  
  const map: Map<string, number> = JSON.parse(state, reviver)

  return map
}