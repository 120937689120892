import { PlayIcon, PuzzleIcon, ShareIcon } from '@heroicons/react/outline'

import { getIsLazy, getIsShowSpotleBot, getIsTaytay, isCoSpotle, isGinJanner, isSixLetter, setShowSpotleBot } from '../App'
import { recordGaEvent } from '../components/alerts/Analytics'
import { Grid } from '../components/grid/Grid'
import { RANDOM } from '../constants/random'
import { getTranslation} from '../context/messages'
import {
  getStoredBotAssistedMode,
  getStoredBotMode,
  getStoredBotStats,
  getStoredDarkMode,
  getStoredIsHighContrastMode,
  getStoredOpponentLevel,
  getStoredParallelMode,
  setStoredBotStats,
  setStoredRemainingSolutions,
  setStoredWordCounts,
} from '../lib/localStorage'
import { shareStatus } from '../lib/share'
import {
  CharStatus,
  getEasyGuessStatuses,
  getGuessStatuses,
} from '../lib/statuses'
import {
  addToPresent,
  allPresentLettersIn,
  countLettersInWord,
  getFirstLetter,
  getIndex,
  getSolution,
  getValidGuesses,
  getWords,
  mergePresent,
} from '../lib/words'
import DetailedBotPlay from './DetailedBotPlay'
import { TranslationKey } from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'
import { getAllGlobalGuesses, getGlobalGuesses } from './globalstats'
import { calculateGuessSkill, calculateSkill } from './helper'

var invalid_letters: Set<string>[]
var conditional_invalid_letters: Set<string>[]
var present: { [letter: string]: number }
var correct_letters: string[]
var hidden_letters: string[]

var hardMode: boolean
var gridHardMode: boolean
var hiddenLetterMode: boolean

var mask: number[][]
var maskIndex: number
var currentFreeLetters: number
var totalFreeLetters: number

function getMask(today: Date, solutionLength: number, playerGuesses?: string[]): number[][] {
  var tempStatuses = getEasyGuessStatuses(today, gridHardMode, solutionLength, hiddenLetterMode)
  if (tempStatuses.size === 0) { 
      const newMask = []
       for(var i = 0; i < MAX_CHALLENGES || (playerGuesses !== undefined && i < playerGuesses.length); i++){
        const newRow = []
        for (let j = 0; j < solutionLength; j++) {
          newRow.push(1)
        }
        newMask.push(newRow)
      }

      return newMask
  }

  var row: number[] = []
  const newMask = []

  for (let i = 0; i < MAX_CHALLENGES || (playerGuesses !== undefined && i < playerGuesses.length); i++) {
    for (let j = 0; j < solutionLength; j++) {
      if (tempStatuses.get(i)![j] === 'hidden') {
        row.push(0)
      } else {
        row.push(1)
      }
    }

    newMask.push(row)
    row = []
  }
  const newRow = []
  for (let j = 0; j < solutionLength; j++) {
    newRow.push(1)
  }
  newMask.push(newRow)

  return newMask
}

function countFreeLetters(gameMask: number[][], index: number): number {
  var freeLetters = 0
  for (let i = index; i < MAX_CHALLENGES - 1; i++) {
    for (let j = 0; j < MAX_CHALLENGES - 1; j++) {
      freeLetters = freeLetters + gameMask[i][j]
    }
  }

  return freeLetters
}

function populateStatuses(statusList: CharStatus[], guess: string): void {
  const tempPresent: { [letter: string]: number } = {}
  for (let idx = 0; idx < statusList.length; idx++) {
    const status = statusList[idx]

    if (status === 'hidden') {
      if(hiddenLetterMode){
        hidden_letters.push(guess[idx])
      }
      //when we know a letter is present,
      //if the current is absent and there's only another letter that is hidden,
      //then that hidden letter is correct
      if (
        guess[idx] in present &&
        countLettersInWord(guess[idx], guess) === 2 &&
        isLetterStatusBefore(guess, idx, statusList, 'absent')
      ) {
        addToPresent(guess[idx], tempPresent)
        correct_letters[idx] = guess[idx]
      } else if (
        guess[idx] in present &&
        countLettersInWord(guess[idx], guess) === 2 &&
        isLetterStatusAfter(guess, idx, statusList, 'present')
      ) {
        //if a letter is present form a previous guess
        //and the letter is hidden (correct/present would be handled normally)
        //and there's a present letter after,
        //we now know we have 2 of that letter in the word
        //the hidden letter would be present or correct
        addToPresent(guess[idx], tempPresent)
      } else if (
        guess[idx] === correct_letters[idx] &&
        countLettersInWord(guess[idx], guess) === 2 &&
        (isLetterStatusAfter(guess, idx, statusList, 'present') ||
          isLetterStatusBefore(guess, idx, statusList, 'present'))
      ) {
        //if a letter correct from a previous
        //and there's a present letter before or after,
        //we now know we have 2 of that letter in the word
        //not adding the hidden would mean missing on that extra info
        //we know the hidden is correct, so normal statuses rules apply
        addToPresent(guess[idx], tempPresent)
      } else if (
        // if we know there's a certain letter in the word
        // and we have that letter twice in the word
        // and the first is present and the second hidden
        // then we either have that letter twice in the solution
        // (second would be green under the hidden)
        // or we have only 1 and under the hidden is grey
        // hence why it's conditional
        guess[idx] in present &&
        guess[idx] !== correct_letters[idx] &&
        countLettersInWord(guess[idx], guess) === 2 &&
        isLetterStatusBefore(guess, idx, statusList, 'present')
      ) {
        conditional_invalid_letters[idx].add(guess[idx])
      }
    } else if (status === 'present') {
      addToPresent(guess[idx], tempPresent)
      invalid_letters[idx].add(guess[idx])
    } else if (status === 'correct') {
      addToPresent(guess[idx], tempPresent)
      correct_letters[idx] = guess[idx]
    } else if (status === 'absent') {
      invalid_letters[idx].add(guess[idx])
      populateWrongLetters(guess, idx, statusList)
    }
  }

  mergePresent(tempPresent, getCorrectLetterCount())
  mergePresent(present, tempPresent)
}

function checkHiddenBeforeIsCorrect(guess: string, letterIdx: number, statusList: CharStatus[]): boolean{
  var correct = false
  for (let idx = 0; idx < letterIdx; idx++) {
    if (guess[idx] === guess[letterIdx] 
      && statusList[idx] === 'hidden'){
        if(correct_letters[idx] === guess[idx]) {
          correct = true
      } else {
        //if there's more than one hidden letter
        return false
      }
    }  
  }
  return correct
}

function populateWrongLetters(
  guess: string,
  letterIdx: number,
  statusList: CharStatus[]
): void {
  if ((isLetterStatusBefore(guess, letterIdx, statusList, 'hidden') &&
    !checkHiddenBeforeIsCorrect(guess, letterIdx, statusList)) ||
    isLetterStatusBefore(guess, letterIdx, statusList, 'present')
  ) {
    return
  }
  for (let idx = 0; idx < guess.length; idx++) {
    if (guess[idx] !== guess[letterIdx]) {
      invalid_letters[idx].add(guess[letterIdx])
    }
  }

  /*this is for a situation where we know the word has a certain letter 
    and the first occurrence of that letter is absent. We confirm that the 
    letter is not visible anythere in the word and if it is, not correct. 
    if the letter is only twice in the word, then the second one is correct, 
    if the letter is 3 times in the word, then we invalidate any other 
    positions that are not the letter instead
  */
  if (
    isLetterStatusAfter(guess, letterIdx, statusList, 'hidden') &&
    isLetterInWordBefore(guess, letterIdx) === -1 &&
    guess[letterIdx] in present &&
    !isLetterStatusAfter(guess, letterIdx, statusList, 'correct')
  ) {
    const count = countLettersInWord(guess, guess[letterIdx])
    if (count === 2) {
      for (let idx = 0; idx < guess.length; idx++) {
        if (guess[idx] !== guess[letterIdx] || letterIdx === idx) {
          invalid_letters[idx].add(guess[letterIdx])
        } else {
          correct_letters[idx] = guess[letterIdx]
        }
      }
    } else if (count === 3) {
      for (let idx = 0; idx < guess.length; idx++) {
        if (guess[idx] !== guess[letterIdx] || letterIdx === idx) {
          invalid_letters[idx].add(guess[letterIdx])
        } //if the letter is twice or more in the word, then we know those two positions are correct
        else if (present[guess[letterIdx]] > 1) {
          correct_letters[idx] = guess[letterIdx]
        }
      }
    }
  }
}

function getIndexesLetterAfter(guess: string, letterIdx: number): number[] {
  const indexes = []
  for (let idx = letterIdx + 1; idx < guess.length; idx++) {
    if (guess[idx] === guess[letterIdx]) {
      indexes.push(idx)
    }
  }
  return indexes
}

function isLetterHiddenAfter(
  guess: string,
  letterIdx: number,
  mask: number[][],
  maskIndex: number
): boolean {
  for (let idx = letterIdx + 1; idx < guess.length; idx++) {
    if (guess[idx] === guess[letterIdx] && mask[maskIndex][idx] === 0) {
      return true
    }
  }
  return false
}

function isLetterStatusAfter(
  guess: string,
  letterIdx: number,
  statusList: CharStatus[],
  status: CharStatus
): boolean {
  for (let idx = letterIdx + 1; idx < guess.length; idx++) {
    if (guess[idx] === guess[letterIdx] && statusList[idx] === status) {
      return true
    }
  }
  return false
}

function isLetterStatusBefore(
  guess: string,
  letterIdx: number,
  statusList: CharStatus[],
  status: CharStatus
): boolean {
  for (let idx = 0; idx < letterIdx; idx++) {
    if (guess[idx] === guess[letterIdx] && statusList[idx] === status) {
      return true
    }
  }
  return false
}

function isLetterInWordBefore(guess: string, letterIdx: number): number {
  for (let idx = 0; idx < letterIdx; idx++) {
    if (guess[idx] === guess[letterIdx]) {
      return idx
    }
  }
  return -1
}

// when the mask shows very little, we need to grab as many letters as possible
// instead of trying to find the placement of a possible letter
function checkUnderThreshold(freeLetters: number): boolean {
  return freeLetters <= 10
}

function scoreWithThreshold(
  counts: Map<string, number>[],
  index: number,
  letter: string
): number | undefined {
  var multiplier = undefined

  if (checkUnderThreshold(totalFreeLetters)) {
    multiplier = 0.05
  } else if (checkUnderThreshold(currentFreeLetters)) {
    multiplier = 0.25
  } else {
    return multiplier
  }

  //while not ideal, not as bad as an impossible letter
  return -1 * maskIndex + counts[index].get(letter)! * multiplier
}

function maybeInSolution(letter: string): boolean {
  for (const set of invalid_letters) {
    if(!set.has(letter)){
      return true
    }
  }

  return false
}

/*if a spot is only visible once, then trying there is more important than
trying in a location that is visible more often*/
function calculateGuessWeights(solutionSize: number): number[] {
  const guessWeights: number[] = []
  const rowsLeft = mask.length

  for(let guessIdx = 0; guessIdx < solutionSize; guessIdx++){
    //starting at 1 to avoid a empty value. 
    //in some situations we value a hidden spot
    var visibleSpots = 0
    for(let idx = maskIndex; idx < mask.length; idx++){
      //1 is visible, 0 is hidden
      visibleSpots = visibleSpots + mask[idx][guessIdx]
    }

    //the more visible the less important it is
    guessWeights[guessIdx] = (rowsLeft - visibleSpots) / rowsLeft

    if(guessWeights[guessIdx] < 0.75) {
      guessWeights[guessIdx] = 0.75
    }
  }

  return guessWeights
}

function letterScore(
  letter_counts: Map<string, number>[],
  duplicate_letter_counts: Map<string, number>[],
  letter_weight: Map<string, number>[],
  letter: string,
  index: number,
  word: string
): number {
  if (mask[maskIndex][index] === 0) {
    if (hiddenLetterMode){
      if(!maybeInSolution(letter) || hidden_letters.includes(letter)){
        return 0
      }

      //unlikely to be in the word again
      if(correct_letters.includes(letter)){
        return 0
      }

      // never seen
      if(!(letter in present)){
        return -1 * letter_counts[index].get(letter)!
      }

      //seen before, not here though
      if(invalid_letters[index].has(letter)){
        return -0.25 * letter_counts[index].get(letter)!
      }
    
      //seen before, could be here
      return -0.50 * letter_counts[index].get(letter)!
      
    }

    return 0
  }

  //not a hidden spot currently
  if(hiddenLetterMode && hidden_letters.includes(letter)){
    //occupying a visible spot with a letter that we know will not give info
    if(invalid_letters[index].has(letter) && !(letter in present)){
      return -0.5 * maskIndex
    } else {
      return -1 * letter_counts[index].get(letter)! - maskIndex
    }
  }

  //not a hidden spot
  if (invalid_letters[index].has(letter)) {
    return -1 * maskIndex
  }

  if (correct_letters[index] === letter) {
    if (hiddenLetterMode) {
      //zero because we have the info already, so probably not needed again
      return 0
    }

    return -1 * maskIndex
  }

  const pastIndex = isLetterInWordBefore(word, index)
  if (pastIndex !== -1) {
    if(letter_weight[index].get(letter)! >= 0.8){
      return -1 * maskIndex
    }

    if (
      invalid_letters[pastIndex].has(letter) ||
      letter_counts[pastIndex].get(letter) === 0
    ) {
      if (correct_letters.includes(letter)) {
        return correctLetterScore(duplicate_letter_counts, letter, index)
      }

      if (letter in present) {
        if (invalid_letters[pastIndex].has(letter)) {
          const presentScore = presentLetterScore(
            letter_counts,
            letter,
            index,
            word,
            true
          )
          return presentScore > 0 ? presentScore * 0.5 : presentScore
        }

        if (checkUnderThreshold(totalFreeLetters)) {
          //while not ideal, not as bad as an impossible letter
          return (
            -1 * maskIndex + duplicate_letter_counts[index].get(letter)! * 0.05
          )
        }
      }

      return indexedLetterScore(letter_counts, letter, index)
    }

    return indexedLetterScore(duplicate_letter_counts, letter, index)
  }

  // the guess has an existing correct letter in the wrong position,
  // meaning it's only useful for a double letter as it will always be yellow
  // or green if it's the first appearing in the word
  if (correct_letters.includes(letter)) {
    if(letter_weight[index].get(letter)! >= 0.8){
      return -1 * maskIndex
    }

    return correctLetterScore(duplicate_letter_counts, letter, index)
  }

  // if we know it's present then we don't need to add value to the other possible positions
  if (letter in present) {
    if(letter_weight[index].get(letter)! >= 0.8){
      return -1 * maskIndex
    }

    return presentLetterScore(letter_counts, letter, index, word)
  }

  var countPlacements = 0
  var totalPlacements = 0
  ;[countPlacements, totalPlacements] = calculatePlacements(
    word,
    index,
    letter,
    letter_counts
  )

  if (letter_counts[index].get(letter)! === 0 && totalPlacements === 0) {
    return -1 * maskIndex
  }

  // letter can only be in the current placement
  if (countPlacements === 0) {
    if (letter_counts[index].get(letter)! === 0) {
      return -1 * maskIndex
    }

    if(letter_weight[index].get(letter)! >= 0.8){
      return -1 * maskIndex
    }

    return letter_counts[index].get(letter)!
  }

  if(letter_weight[index].get(letter)! >= 0.8){
    return 0
  }

  //if the letter is absent, is absent everywhere (not a double letter here, that's handled above)
  //if the letter is present, we know it's the other placement
  if (countPlacements === 1) {
    return letter_counts[index].get(letter)! + totalPlacements
  }

  return letter_counts[index].get(letter)! + totalPlacements / countPlacements
}

function correctLetterScore(
  duplicate_letter_counts: Map<string, number>[],
  letter: string,
  index: number
): number {
  //this is a terrible guess but it could still show a possible double letter
  //it will be either yellow or green
  if (duplicate_letter_counts[index].get(letter)! === 0) {
    //impossible to have a double letter in this location
    return -1 * maskIndex
  }

  const thresholdScore = scoreWithThreshold(
    duplicate_letter_counts,
    index,
    letter
  )
  if (thresholdScore !== undefined) {
    return thresholdScore
  }

  return duplicate_letter_counts[index].get(letter)!
}

function presentLetterScore(
  letter_counts: Map<string, number>[],
  letter: string,
  index: number,
  word: string,
  letterBefore?: boolean
): number {
  if (letter_counts[index].get(letter)! === 0) {
    return -1 * maskIndex
  }

  const thresholdScore = scoreWithThreshold(letter_counts, index, letter)
  if (thresholdScore !== undefined) {
    return thresholdScore
  }

  //if the current letter is grey, then the hidden one will be green, hence we are able to get value in this way too
  if (
    isLetterHiddenAfter(word, index, mask, maskIndex) &&
    letterBefore === undefined
  ) {
    const lettersIndex = getIndexesLetterAfter(word, index)

    //we know it's the hidden one
    if (lettersIndex.length === 1) {
      if (
        invalid_letters[lettersIndex[0]].has(letter) ||
        correct_letters[lettersIndex[0]] !== '' ||
        letter_counts[lettersIndex[0]].get(letter) === 0
      ) {
        //that second guess is pointless and will add no info
        return letter_counts[index].get(letter)! * 0.5 + maskIndex - 6
      }

      //we already know the letter is present, so no need to actively try at all costs to find it's placement
      //that will eventually come
      return (
        letter_counts[index].get(letter)! * 0.5 +
        letter_counts[lettersIndex[0]].get(letter)! * 0.25
      )
    } else {
      //while 3 letters is possible, it's highly unlikely
      return letter_counts[index].get(letter)! * 0.25
    }
  }

  var count = 0
  var total = 0
  ;[count, total] = calculatePlacements(word, index, letter, letter_counts)

  // if we have 1 extra place available, 1 letter will give info about each position
  // if we have 2 extra places available, 2 letters will do the same
  // the method above ignores the same letter if placed twice in a word
  // to remind that countPlancements will ignore the current index, so it's value is actually 1 off reality
  // no need to check when the count is below present because that's done before the counts are done
  // as that would mean we knew where the letter is already
  if (present[letter] === count) {
    return letter_counts[index].get(letter)! * 0.5 + total * 0.25
  }

  return letter_counts[index].get(letter)! * 0.5
}

function indexedLetterScore(
  counts: Map<string, number>[],
  letter: string,
  index: number
): number {
  if (counts[index].get(letter)! === 0) {
    return -1 * maskIndex
  }

  return counts[index].get(letter)!
}

function calculatePlacements(
  word: string,
  index: number,
  letter: string,
  letter_counts: Map<string, number>[]
): [number, number] {
  var countPlacements = 0
  var totalPlacements = 0
  for (let idx = 0; idx < word.length; idx++) {
    //not ignoring the same letter as it is already a placement, just a direct one
    if (
      idx === index ||
      invalid_letters[idx].has(letter) ||
      correct_letters[idx] !== '' ||
      letter_counts[idx].get(letter)! === 0
    ) {
      continue
    }
    countPlacements++
  }

  for (let idx = 0; idx < word.length; idx++) {
    //ignore the same index or the same letter (the last will be handled separately)
    if (
      idx === index ||
      letter === word[idx] ||
      invalid_letters[idx].has(letter) ||
      correct_letters[idx] !== '' ||
      letter_counts[idx].get(letter)! === 0
    ) {
      continue
    }
    totalPlacements = totalPlacements + letter_counts[idx].get(letter)!
  }

  return [countPlacements, totalPlacements]
}

function removeInvalidWord(solutions: string[]): string[] {
  if (present.length === 0) {
    return solutions
  }

  const newWords = []
  for (const word of solutions) {
    if (allPresentLettersIn(present, word)[0]) {
      newWords.push(word)
    }
  }

  return newWords
}

function handleGinJanner(words: string[], guessIndex: number, today: Date): string[] {
  const firstLetter = getFirstLetter(today, guessIndex, 0).toLowerCase()
  if(!isGinJanner() || firstLetter === '') { 
    return words
  }

  const newWords = []
  for (const word of words) {
    if (word.startsWith(firstLetter)) {
      newWords.push(word) 
    }
  }

  return newWords
}

function getCorrectLettersIndexes(): number[] {
  var indexes = []
  for (var index = 0; index < correct_letters.length; index++) {
    if (correct_letters[index] !== '') {
      indexes.push(index)
    }
  }

  return indexes
}

function removeNonSolutionWords(solutions: string[]): string[] {
  const correctIndexes = getCorrectLettersIndexes()
  if (correctIndexes.length === 0) {
    return solutions
  }

  const newWords = []
  for (const word of solutions) {
    for (const idx of correctIndexes) {
      if (correct_letters[idx] !== word[idx]) {
        break
      }

      if (idx === correctIndexes[correctIndexes.length - 1]) {
        newWords.push(word)
      }
    }
  }

  return newWords
}

function getInvalidLettersIndexes(invalid: Set<string>[]): number[] {
  var indexes = []
  for (var index = 0; index < invalid.length; index++) {
    if (invalid[index].size !== 0) {
      indexes.push(index)
    }
  }

  return indexes
}

function removeWordsWithLettersInInvalidPositions(words: string[], invalid: Set<string>[], conditional: boolean): string[] {
  const invalidIndexes = getInvalidLettersIndexes(invalid)
  if (invalidIndexes.length === 0) {
    return words
  }

  const newWords = []
  for (const word of words) {
    for (const idx of invalidIndexes) {
      // conditionals only work with word that have the letter ONCE
      // that is the only scenario where the letter is invalid in that position
      if (invalid[idx].has(word[idx]) && (!conditional || countLettersInWord(word[idx], word) === 1)) {
        break
      }

      if (idx === invalidIndexes[invalidIndexes.length - 1]) {
        newWords.push(word)
      }
    }
  }

  return newWords
}

function removeGuessesFromSolutions(solutions: string[], guesses: string[]) {
  for (const guess of guesses) {
    const index = solutions.indexOf(guess.toLowerCase())

    if (index !== -1) {
      solutions.splice(index, 1)
    }
  }
  return solutions
}

const populateLetterCounts = (
  letter_counts: Map<string, number>[],
  duplicate_letter_counts: Map<string, number>[],
  words: string[], 
  guessWeights: number[]
) => {
  var firstLetterValue = 1
  var secondLetterValue = 0.7

  if (checkUnderThreshold(totalFreeLetters)) {
    firstLetterValue = 0.75
    secondLetterValue = 0.65
  }

  for (const word of words) {
    const wordLetterCount: { [letter: string]: number } = {}
    for (const letter of word) {
      addToPresent(letter, wordLetterCount)
    }

    for (let index = 0; index < word.length; index++) {
      const letter = word.charAt(index)

      const count = wordLetterCount[letter]
      if (count > 1) {
        duplicate_letter_counts[index].set(
          letter,
          duplicate_letter_counts[index]!.get(letter)! + guessWeights[index]
        )
        populateDuplicatedLetterCount(
          letter_counts,
          word,
          index,
          firstLetterValue * guessWeights[index],
          secondLetterValue * guessWeights[index]
        )
      } else {
        letter_counts[index].set(letter, letter_counts[index]!.get(letter)! + guessWeights[index])
      }
    }
  }
}

function populateDuplicatedLetterCount(
  letter_counts: Map<string, number>[],
  word: string,
  index: number,
  firstLetterValue: number,
  secondLetterValue: number
): void {
  const letter = word.charAt(index)

  if (isLetterInWordBefore(word, index) === -1) {
    letter_counts[index].set(
      letter,
      letter_counts[index]!.get(letter)! + firstLetterValue
    )
  } else {
    letter_counts[index].set(
      letter,
      letter_counts[index]!.get(letter)! + secondLetterValue
    )
  }
}

function populateLetterWeight(
  letter_counts: Map<string, number>[], 
  letter_weight: Map<string, number>[],
  numberOfSolutions: number,
  solutionSize: number
): void {
  for (let i = 0; i < solutionSize; i++) {
    letter_counts[i].forEach((value: number, key: string) => {
      if(value !== 0){
        letter_weight[i].set(key, value / numberOfSolutions)
      }
    })
  }
}

function getUniqueWords(words: string[], solutions: string[]): string[] {
  if (words.length === 1) {
    return words
  }

  var tempWords = []
  for (const word of words) {
    if (solutions.includes(word)) {
      tempWords.push(word)
    }
  }

  if (tempWords.length !== 0) {
    words = tempWords
  }

  const newWords: { [key: string]: number } = {}
  for (const word of words) {
    const tempSet = new Set<string>()
    for (const letter of word) {
      tempSet.add(letter)
    }
    newWords[word] = tempSet.size
  }

  var maxCount: number = 0
  var maxCountList: string[] = []
  for (const [word, count] of Object.entries(newWords)) {
    if (maxCount < count) {
      maxCountList = [word]
      maxCount = count
    } else if (maxCount === count) {
      maxCountList.push(word)
    }
  }

  return maxCountList
}

function getSolutions(words: string[], solutions: string[]): string[] {
  var tempWords = []
  for (const word of words) {
    if (solutions.includes(word)) {
      tempWords.push(word)
    }
  }

  return tempWords
}

function getCorrectLetterCount(): { [letter: string]: number } {
  const tempPresent: { [letter: string]: number } = {}

  for (let i = 0; i < correct_letters.length; i++) {
    if (correct_letters[i] !== '') {
      addToPresent(correct_letters[i], tempPresent)
    }
  }

  return tempPresent
}

function isFindNewCorrectLettersPossible(tempCorrect: Set<string>[]): boolean {
  for (const set of tempCorrect) {
    if (set.size <= 1) {
      return true
    }
  }

  return false
}

function checkNewCorrectLetters(solutions: string[]): void {
  var tempCorrect: Set<string>[] = [
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
  ]

  if(isSixLetter()) {
    tempCorrect.push(new Set<string>())
  }

  for (const possSolution of solutions) {
    for (let i = 0; i < possSolution.length; i++) {
      tempCorrect[i].add(possSolution[i])
    }

    if (!isFindNewCorrectLettersPossible(tempCorrect)) {
      tempCorrect = []
      break
    }
  }

  for (let i = 0; i < tempCorrect.length; i++) {
    if (tempCorrect[i].size === 1 && correct_letters[i] === '') {
      correct_letters[i] = tempCorrect[i].values().next().value
    }
  }

  mergePresent(present, getCorrectLetterCount())
}

function checkNewPresentLetters(solutions: string[]): void {
  var tempPresentLetters: Map<string, number> = new Map()
  for (const letter of solutions[0]) {
    if (!tempPresentLetters.has(letter)) {
      tempPresentLetters.set(letter, 1)
    } else {
      tempPresentLetters.set(letter, tempPresentLetters.get(letter)! + 1)
    }
  }

  for (let i = 1; i < solutions.length; i++) {
    var newPresentLetters: Map<string, number> = new Map()
    //loops the letters we know are common to all solutions
    for (let letter of Array.from(tempPresentLetters.keys())) {
      if (solutions[i].includes(letter)) {
        if (!newPresentLetters.has(letter)) {
          newPresentLetters.set(letter, 1)
        } else {
          newPresentLetters.set(letter, newPresentLetters.get(letter)! + 1)
        }
      }
    }
    tempPresentLetters = newPresentLetters
  }

  const tempPresent: { [letter: string]: number } = {}
  for (let letter of Array.from(tempPresentLetters.keys())) {
    for (let i = 0; i < tempPresentLetters.get(letter)!; i++) {
      addToPresent(letter, tempPresent)
    }
  }

  mergePresent(present, tempPresent)
}

function checkNewInvalidLetters(solutions: string[]): void {
  if (solutions.length === getWords().length) {
    return
  }

  var totalLetters: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('')

  for (const possSolution of solutions) {
    for (const letter of possSolution) {
      const index = totalLetters.indexOf(letter)

      if (index !== -1) {
        totalLetters.splice(index, 1)
      }

      if (totalLetters.length === 0) {
        return
      }
    }
  }

  for (const letter of totalLetters) {
    for (const set of invalid_letters) {
      set.add(letter)
    }
  }
}

function onlyCorrectVisible(): boolean{
  for (let idx = 0; idx < mask[maskIndex].length; idx++) {
    if (mask[maskIndex][idx] === 1 && correct_letters[idx] === '') {
      return false
    }
  }

  return true
}

export const botGuessCalculator = (
  guesses: string[],
  player: boolean, 
  today: Date
): [number, number, Map<number, string[]>] => {
  var words: string[] = Object.assign([], getValidGuesses())
  var solutions: string[] = Object.assign([], getWords())

  if(!getIsShowSpotleBot() && getStoredBotMode() && getStoredOpponentLevel() <= 6){
    // done to make the bot dumber so it doesn't get the word 
    // as easily when the number of solutions left is 1
    solutions = Object.assign([], getValidGuesses())
  } 
  
  for (const guess of guesses) {
    const index = solutions.indexOf(guess.toLowerCase())

    if (index !== -1) {
      solutions.splice(index, 1)
    }
  }

  // Create a dictionary to store the letter counts
  const letter_counts: Map<string, number>[] = [
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
  ]

  const duplicate_letter_counts: Map<string, number>[] = [
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
  ]

  const letter_weight: Map<string, number>[] = [
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
    new Map<string, number>(),
  ]

  if(isSixLetter()){
    letter_counts.push(new Map<string, number>())
    duplicate_letter_counts.push(new Map<string, number>())
    letter_weight.push(new Map<string, number>())
  }

  // Create a new dictionary to store the word counts
  const word_counts: Map<number, string[]> = new Map<number, string[]>()

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  for (const letter of alphabet) {
    for (const map of letter_counts) {
      map.set(letter, 0)
    }
    for (const map of duplicate_letter_counts) {
      map.set(letter, 0)
    }
  }

  // Iterate through the words and update the letter counts
  // this is done to make sure we only value letters from possible solutions
  solutions = removeNonSolutionWords(solutions)
  solutions = removeInvalidWord(solutions)
  solutions = removeWordsWithLettersInInvalidPositions(solutions, invalid_letters, false)
  solutions = removeWordsWithLettersInInvalidPositions(solutions, conditional_invalid_letters, true)
  solutions = removeGuessesFromSolutions(solutions, guesses)

  //taytay mode includes duplicate solutions
  if (getIsTaytay()) {
    var tempSolutions: string[] = []
    for (const word of solutions) {
      if (!tempSolutions.includes(word)) {
        tempSolutions.push(word)
      }
    }
    solutions = tempSolutions
  }

  checkNewPresentLetters(solutions)
  checkNewCorrectLetters(solutions)
  checkNewInvalidLetters(solutions)

  const guessWeights = calculateGuessWeights(solutions[0].length)
  
  populateLetterCounts(letter_counts, duplicate_letter_counts, solutions, guessWeights)
  populateLetterWeight(letter_counts, letter_weight, solutions.length, solutions[0].length)

  /* if on the first guess and the list of solutions is 6 or less, then just try your luck
   if on the third guess and the list of solutions is 4 or less, then just try your luck
   mask index goes from 0 to 5 so
   3 or less because we can use a filler to reduce the number of total guesses
   1st 6 - 0 6 solutions
   2nd 6 - 1 5 solutions
   3rd 6 - 2 4 solutions  
   4th 6 - 3 3 solutions
   5th 6 - 4 2 solutions
   6th 6 - 5 1 solution
  */
  if (((isCoSpotle() && getStoredParallelMode()) || getIsShowSpotleBot()) 
   && solutions.length <= MAX_CHALLENGES - maskIndex 
   && !player
   && !isGinJanner()) {
    words = solutions 
  }

  /*
    !getIsShowSpotleBot added to avoid having the bot make non ideal games 
    when a player is looking at how the bot would have played.
    the guesses.length >= 4 is added to try and make sure the bot doesn't leave the game wide open 
    on the very last guess
  */
  if (((isCoSpotle() && getStoredParallelMode()) || getIsShowSpotleBot()) && guesses.length >= MAX_CHALLENGES - 1) {
    words = solutions
  } else if(isCoSpotle() 
      && guesses.length >= maxGuessesLength()
      && !getIsShowSpotleBot()){
    words = solutions
  } else if (hardMode) {
    if(onlyCorrectVisible()){
      words = solutions
    } else {
      words = removeNonSolutionWords(words)
      words = removeInvalidWord(words)
    }
  }

  if(solutions.length === 1 && isGinJanner()) {
    words = solutions 
  } else {
    words = handleGinJanner(words, guesses.length, today)
  }

  // Iterate through the words
  var maxCount = -999998
  var secondMaxCount = -999999
  for (const word of words) {
    // Initialize the count for the current word to 0
    let count = 0
    // create a set to store unique letters of the current word
    for (let index = 0; index < word.length; index++) {
      count += letterScore(
        letter_counts,
        duplicate_letter_counts,
        letter_weight,
        word.charAt(index),
        index,
        word
      )
    }

    if (count > maxCount) {
      secondMaxCount = maxCount
      maxCount = count
    } else if (count > secondMaxCount){
      secondMaxCount = count
    }

    if (!word_counts.has(count)) {
      word_counts.set(count, [])
    }

    word_counts.get(count)!.push(word)
  }

  return [maxCount, secondMaxCount, word_counts]
}

function maxGuessesLength(): number{
  if(getStoredOpponentLevel() <= 4) {
    return 13
  } else if(getStoredOpponentLevel() <= 5) {
    return 11
  } else if(getStoredOpponentLevel() <= 7) {
    return 7
  } else if(getStoredOpponentLevel() <= 9) {
    return 5
  }

  return 3
}

function getLevelBasedAttempts(
  level: number,
  word_counts: Map<number, string[]>,
  step: number
): string[] {
  var keys: number[] = Array.from(word_counts.keys())
  keys = keys.sort((n1, n2) => n2 - n1)

  const maxScore = keys[0]
  const lowestScore = keys[keys.length - 1]
  const spread = maxScore - lowestScore
  const lowerLevel = adjustedLevel(level) - step
  const higherLevel = adjustedLevel(level) + step

  if(spread === 0){
    return word_counts.get(maxScore)!
  }

  var attempts = []
  for (let idx = 0; idx < keys.length; idx++) {
    const localSpread  = keys[idx] - lowestScore
    if ((localSpread / spread) * 10 >= lowerLevel && (localSpread / spread) * 10 <= higherLevel) {
      for(const word of word_counts.get(keys[idx])!){
        attempts.push(word)
      }
    }
  }

  return attempts
}

//in place because level 1, 2 and 3 do not use the bot
function adjustedLevel(level: number): number {
  if(level <= 5){
    return 1
  } else if (level <= 6){
    return 3
  } else if(level <= 7){
    return 5
  } else if(level <= 9){
    return 6
  }
  return 7
}

//skill, words left
export function createStats(guessesLength: number): string[][] {
  var stats = []
  for (let i = 0; i < guessesLength; i++) {
    stats.push(['', '', ''])
  }

  return stats
}

export function bot(
  propHardMode: boolean,
  propGridHardMode: boolean,
  propHiddenLetterMode: boolean,
  today: Date,
  solution: string,
  lazyModeGuesses?: string[],
  lazyModeStatuses?: Map<number, CharStatus[]>
): [string[], Map<number, CharStatus[]>, string[][]] {
  invalid_letters = [
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
  ]
  conditional_invalid_letters = [
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
  ]
  correct_letters = ['', '', '', '', '']

  if(isSixLetter()){
    invalid_letters.push(new Set<string>())
    conditional_invalid_letters.push(new Set<string>())
    correct_letters.push('')
  }

  present = {}
  hidden_letters = []
  hardMode = propHardMode
  gridHardMode = propGridHardMode
  hiddenLetterMode = propHiddenLetterMode

  mask = getMask(today, solution.length, lazyModeGuesses)
  maskIndex = 0
  totalFreeLetters = countFreeLetters(mask, 0)

  //var innerCount = 0
  var guesses: string[] = []
  var statuses = getEasyGuessStatuses(today, gridHardMode, solution.length, hiddenLetterMode)
  var botStats = createStats(lazyModeGuesses !== undefined && lazyModeGuesses.length > MAX_CHALLENGES 
    ? lazyModeGuesses!.length : MAX_CHALLENGES)

  if (
    lazyModeGuesses !== undefined &&
    lazyModeStatuses !== undefined
  ) {
    //making a copy minus the last guess that will then be removed
    //this happens so the bot can try and play the same game as the player
    //because the initial game had five pre played guesses
    for (let i = 0; i < lazyModeGuesses.length - 1; i++) {
      statuses.set(i, lazyModeStatuses.get(i)!)
    }

    guesses = lazyModeGuesses!.slice(0, lazyModeGuesses.length - 1) //removing last guess

    botStats = playerEvaluator(
      hardMode,
      gridHardMode,
      hiddenLetterMode,
      today,
      solution,
      guesses,
      statuses
    )

    maskIndex = guesses.length
  }

  var maxScore: number = -999999
  var word_counts: Map<number, string[]> = new Map()

  while (maskIndex < MAX_CHALLENGES || (lazyModeGuesses !== undefined && maskIndex < lazyModeGuesses.length)) {
    currentFreeLetters = countFreeLetters(mask, maskIndex)

    maxScore = -999999
    var secondMaxScore: number
    word_counts = new Map()
    ;[maxScore, secondMaxScore, word_counts] = botGuessCalculator(guesses, false, today)
    setStoredWordCounts(word_counts, maskIndex)

    var solutions = removeNonSolutionWords(getWords())
    solutions = removeInvalidWord(solutions)
    solutions = removeWordsWithLettersInInvalidPositions(solutions, invalid_letters, false)
    solutions = removeWordsWithLettersInInvalidPositions(solutions, conditional_invalid_letters, true)
    solutions = removeGuessesFromSolutions(solutions, guesses)

    var attempts: string[] = word_counts.get(maxScore)!
    var secondAttempts: string[] = word_counts.get(secondMaxScore)!

    if(!getIsShowSpotleBot() && isCoSpotle() && getStoredBotMode()){
      var step = 0
      attempts = []

      while(attempts.length === 0){
        attempts = getLevelBasedAttempts(getStoredOpponentLevel(), word_counts, step)
        step = step + 0.25
      }
      
      secondAttempts = attempts
    }

    //this will try to grab the solutions from the attempts list
    if(checkUnderThreshold(currentFreeLetters) && getSolutions(attempts, solutions).length > 0){
      attempts = getUniqueWords(attempts, solutions)
    } else if (checkUnderThreshold(currentFreeLetters) && getSolutions(secondAttempts, solutions).length > 0){
      attempts = getUniqueWords(secondAttempts, solutions)
    } else {
      attempts = getUniqueWords(attempts, solutions)
    }

    const randomNumber: number = +RANDOM[getIndex(today) % RANDOM.length]
    //const randomNumber = 0
    const attempt = attempts[randomNumber % attempts.length]

    guesses.push(attempt.toUpperCase())

    if (attempt.toUpperCase() === solution) {
      var winStatuses: CharStatus[] = []
      for (var i = 0; i < solution.length; i++) {
        winStatuses.push('correct')
      }
      statuses.set(maskIndex, winStatuses)

      if(botStats[maskIndex] === undefined){
        botStats.push(['', '', ''])
      }

      botStats[maskIndex][0] = calculateSkill(maxScore, word_counts).toString()
      botStats[maskIndex][1] = '0'
      botStats[maskIndex][2] = calculateLuck(botStats, maskIndex, 0, 100)

      setStoredRemainingSolutions(maskIndex, solutions, false)
      break
    } else {
      statuses = getGuessStatuses(maskIndex, solution, guesses, statuses, today, hiddenLetterMode)
    }

    populateStatuses(statuses.get(maskIndex)!, attempt)
    
    solutions = removeNonSolutionWords(solutions)
    solutions = removeInvalidWord(solutions)
    solutions = removeWordsWithLettersInInvalidPositions(solutions, invalid_letters, false)
    solutions = removeWordsWithLettersInInvalidPositions(solutions, conditional_invalid_letters, true)
    solutions = removeGuessesFromSolutions(solutions, guesses)

    const indexSolutions = solutions.indexOf(attempt)
    if (indexSolutions !== -1) {
      solutions.splice(indexSolutions, 1)
    }

    const skill = calculateSkill(maxScore, word_counts)

    if(botStats[maskIndex] === undefined){
      botStats.push(['', '', ''])
    }

    botStats[maskIndex][0] = skill.toString()
    botStats[maskIndex][1] = solutions.length.toString()
    botStats[maskIndex][2] = calculateLuck(
      botStats,
      maskIndex,
      solutions.length,
      skill
    )

    setStoredRemainingSolutions(maskIndex, solutions, false)
    maskIndex = maskIndex + 1
  }

  return [guesses, statuses, botStats]
}

function playerEvaluator(
  propHardMode: boolean,
  propGridHardMode: boolean,
  propHiddenLetterMode: boolean,
  today: Date,
  solution: string,
  playerGuesses: string[],
  playerStatuses: Map<number, CharStatus[]>
): string[][] {
  invalid_letters = [
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
  ] 
  conditional_invalid_letters = [
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
    new Set<string>(),
  ]
  correct_letters = ['', '', '', '', '']

  if(isSixLetter()){
    invalid_letters.push(new Set<string>())
    conditional_invalid_letters.push(new Set<string>())
    correct_letters.push('')
  }

  present = {}
  hidden_letters = []
  hardMode = propHardMode
  gridHardMode = propGridHardMode
  hiddenLetterMode = propHiddenLetterMode

  mask = getMask(today, solution.length, playerGuesses)
  maskIndex = 0
  totalFreeLetters = countFreeLetters(mask, 0)

  //var innerCount = 0
  var tempGuesses: string[] = []
  var botStats = createStats(playerGuesses.length)

  while ((maskIndex < MAX_CHALLENGES || maskIndex < playerGuesses.length) 
    && typeof playerGuesses[maskIndex] !== 'undefined') {
    currentFreeLetters = countFreeLetters(mask, maskIndex)

    //one guess in the past so the bot can determine what the best guess would have been
    var word_counts: Map<number, string[]> = botGuessCalculator(tempGuesses, true, today)[2]

    populateStatuses(
      playerStatuses.get(maskIndex)!,
      playerGuesses[maskIndex].toLocaleLowerCase()
    )

    tempGuesses.push(playerGuesses[maskIndex])
    if (playerGuesses[maskIndex].toUpperCase() === solution) {
      if(botStats[maskIndex] === undefined){
        botStats.push(['', '', ''])
      }

      botStats[maskIndex][0] = calculateGuessSkill(playerGuesses[maskIndex], word_counts).toString()
      botStats[maskIndex][1] = '0'
      botStats[maskIndex][2] = calculateLuck(botStats, maskIndex, 0, 100)

      setStoredRemainingSolutions(maskIndex, [], true)
      break
    }

    var possibleSolutions: string[] = Object.assign([], getWords())
    possibleSolutions = removeNonSolutionWords(possibleSolutions)
    possibleSolutions = removeInvalidWord(possibleSolutions)
    possibleSolutions = removeWordsWithLettersInInvalidPositions(possibleSolutions, invalid_letters, false)
    possibleSolutions = removeWordsWithLettersInInvalidPositions(possibleSolutions, conditional_invalid_letters, true)
    possibleSolutions = removeGuessesFromSolutions(
      possibleSolutions,
      tempGuesses
    )

    for (const playerGuess of tempGuesses) {
      const indexSolutions = possibleSolutions.indexOf(
        playerGuess.toLocaleLowerCase()
      )
      if (indexSolutions !== -1) {
        possibleSolutions.splice(indexSolutions, 1)
      }
    }

    const skill = calculateGuessSkill(playerGuesses[maskIndex], word_counts)

    if(botStats[maskIndex] === undefined){
      botStats.push(['', '', ''])
    }

    botStats[maskIndex][0] = skill.toString()
    botStats[maskIndex][1] = possibleSolutions.length.toString()
    botStats[maskIndex][2] = calculateLuck(
      botStats,
      maskIndex,
      possibleSolutions.length,
      skill
    )

    setStoredRemainingSolutions(maskIndex, possibleSolutions, true)
    maskIndex = maskIndex + 1
  }

  return botStats
}

/**
 *
 * Luck can be seen by just how much info you got.
 * Of course  abetter guess will reduce the amount of luck,
 * because that's what makes a good guess, but luck,
 * deep down, is just getting the most info possible with a single guess
 *
 * @param stats
 * @param index
 * @param solutionsLeft
 * @param skill
 * @returns
 */
function calculateLuck(
  stats: string[][],
  index: number,
  solutionsLeft: number,
  skill: number
): string {
  var oldSolutionsLeft = 0
  if (index === 0) {
    oldSolutionsLeft = getWords().length
  } else {
    oldSolutionsLeft = +stats[index - 1][1]
  }

  //reversing the skill because the lower the skill the higher the luck
  skill = 100 - skill
  const infoGained =
    ((oldSolutionsLeft - solutionsLeft) / (oldSolutionsLeft * 1.0)) * 100

  if (infoGained === 0) {
    return '0'
  }

  if (solutionsLeft === 0 && oldSolutionsLeft === 1) {
    return skill.toString()
  }

  return Math.round(infoGained * 0.7 + skill * 0.3).toString()
}

const SpotleBot = (props: {
  today: Date
  hardMode: boolean
  gridHardMode: boolean
  hiddenLetterMode: boolean
  playerGuesses: string[]
  playerStatuses: Map<number, CharStatus[]>
  handleShareToClipboard: () => void
  handleShareFailure: () => void
  handleBotSwitch: () => void
  botPageNumber: number
  handleIncBotPageNumber: () => void
  handleDecBotPageNumber: () => void
  onOpenSolutionsPage: Function
}) => {
  recordGaEvent('spotle_bot')

  if (typeof props.today === 'undefined') {
    return <div />
  }

  if (getStoredDarkMode()) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  const solution = getSolution(getIndex(props.today))
  var guesses = []
  var statuses = getEasyGuessStatuses(props.today, props.gridHardMode, solution.length, hiddenLetterMode)
  var botStats: string[][] = getStoredBotStats(false, MAX_CHALLENGES)
  var playerBotStats: string[][] = getStoredBotStats(true, MAX_CHALLENGES)

  if(getIsLazy() && !getStoredBotAssistedMode(true)){
    ;[guesses, statuses, botStats] = bot(
      props.hardMode!,
      props.gridHardMode!,
      props.hiddenLetterMode!,
      props.today,
      solution,
      props.playerGuesses,
      props.playerStatuses
    )
  } else{
    ;[guesses, statuses, botStats] = bot(
      props.hardMode!,
      props.gridHardMode!,
      props.hiddenLetterMode!,
      props.today,
      solution
    )
  }

  playerBotStats = createStats(props.playerGuesses.length > MAX_CHALLENGES 
    ? props.playerGuesses.length : MAX_CHALLENGES)
  if (
    typeof props.playerGuesses !== 'undefined' &&
    typeof props.playerStatuses !== 'undefined' &&
    props.playerGuesses!.length > 0
  ) {
    playerBotStats = playerEvaluator(
      props.hardMode!,
      props.gridHardMode!,
      props.hiddenLetterMode!,
      props.today,
      solution,
      props.playerGuesses!,
      props.playerStatuses!
    )
    setStoredBotStats(playerBotStats, true)
  }
  
  const titles: string[] = []
  for (let i = 0; i < solution.length; i++) {
    titles.push('')
  }

  titles.push(getTranslation(TranslationKey.SKILL))
  titles.push(getTranslation(TranslationKey.WORDS_LEFT))
  titles.push(getTranslation(TranslationKey.LUCK))

  for(let i = 1; i <= MAX_CHALLENGES; i++) {
    getAllGlobalGuesses(props.today, i)
    getGlobalGuesses(props.today, i)
  }

  const pageNumber = props.botPageNumber
  return (
    <>
      {pageNumber === -1 && (
        <div className="overflow-y: scroll flex grow flex-col justify-center pb-6 short:pb-2">
        <p className="mx-0.5 flex items-center justify-center text-4xl font-bold dark:text-white short:text-2xl">
          {getTranslation(TranslationKey.YOUR_GAME)}
        </p>
        <Grid
          guesses={props.playerGuesses!}
          currentGuess={''}
          isRevealing={false}
          currentRowClassName={''}
          statuses={props.playerStatuses!}
          botStats={playerBotStats}
          titles={titles}
          solution={solution}
          onOpenSolutionsPage={props.onOpenSolutionsPage}
        />
        <br></br>
        <div className="flex justify-center pb-6 short:pb-2">
          <button
            type="button"
            className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={() => {
              shareStatus(
                props.playerGuesses,
                props.playerGuesses[props.playerGuesses.length - 1] !== solution,
                props.hardMode,
                props.gridHardMode,
                props.hiddenLetterMode,
                getStoredIsHighContrastMode(),
                props.handleShareToClipboard,
                props.handleShareFailure,
                props.playerStatuses
              )
            }}
          >
            <ShareIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            {getTranslation(TranslationKey.SHARE_TEXT)}
          </button>
          <button
            type="button"
            className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={() => {
              setShowSpotleBot(false)
              props.handleBotSwitch()
            }}
          >
            <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            {getTranslation(TranslationKey.RETURN)}
          </button>
          {!getIsLazy() && (
          <button
            type="button"
            className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              props.handleIncBotPageNumber()
            }}
          >
            <PuzzleIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            {getTranslation(TranslationKey.GAME_ANALYSIS)}
          </button>
          )}
        </div>
        <br></br>
        <p className="mx-0.5 flex items-center justify-center text-4xl font-bold dark:text-white short:text-2xl">
          {getTranslation(TranslationKey.BOTS_GAME)}
        </p>
        <Grid
          guesses={guesses!}
          currentGuess={''}
          isRevealing={false}
          currentRowClassName={''}
          statuses={statuses!}
          botStats={botStats}
          titles={titles}
          solution={solution}
          onOpenSolutionsPage={props.onOpenSolutionsPage}
          botGame={true}
        />
      </div>
      )}
      {pageNumber > -1 && (
        <DetailedBotPlay 
          today={props.today}
          hardMode={props.hardMode}
          gridHardMode={props.gridHardMode}
          hiddenLetterMode={props.hiddenLetterMode}
          playerGuesses={props.playerGuesses}
          playerStatuses={props.playerStatuses}
          handleBotSwitch={props.handleBotSwitch}
          botPageNumber={props.botPageNumber}
          handleIncBotPageNumber={props.handleIncBotPageNumber}
          handleDecBotPageNumber={props.handleDecBotPageNumber}
          onOpenSolutionsPage={props.onOpenSolutionsPage}
        />
      )}             
    </>
  )
}

export default SpotleBot
