export enum TranslationKey {
  GAME_TITLE = 'GAME_TITLE',
  PLAY = 'PLAY',
  WIN_MESSAGES = 'WIN_MESSAGES',
  GAME_COPIED_MESSAGE = 'GAME_COPIED_MESSAGE',
  NOT_ENOUGH_LETTERS_MESSAGE = 'NOT_ENOUGH_LETTERS_MESSAGE',
  WORD_NOT_FOUND_MESSAGE = 'WORD_NOT_FOUND_MESSAGE',
  HARD_MODE_ALERT_MESSAGE = 'HARD_MODE_ALERT_MESSAGE',
  GRID_HARD_MODE_ALERT_MESSAGE = 'GRID_HARD_MODE_ALERT_MESSAGE',
  HIDDEN_LETTER_ALERT_MESSAGE = 'HIDDEN_LETTER_ALERT_MESSAGE',
  GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE = 'GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE',
  UNLIMITED_MODE_ALERT_MESSAGE = 'UNLIMITED_MODE_ALERT_MESSAGE',
  HARD_MODE_DESCRIPTION = 'HARD_MODE_DESCRIPTION',
  GRID_HARD_MODE_DESCRIPTION = 'GRID_HARD_MODE_DESCRIPTION',
  HIDDEN_LETTER_MODE_DESCRIPTION = 'HIDDEN_LETTER_MODE_DESCRIPTION',
  DARK_MODE_DESCRIPTION = 'DARK_MODE_DESCRIPTION',
  HIGH_CONTRAST_MODE_DESCRIPTION = 'HIGH_CONTRAST_MODE_DESCRIPTION',
  UNLIMITED_MODE_DESCRIPTION = 'UNLIMITED_MODE_DESCRIPTION',
  DAILY_MODE_DESCRIPTION = 'DAILY_MODE_DESCRIPTION',
  UNLIMITED_MODE = 'UNLIMITED_MODE',
  DAILY_MODE = 'DAILY_MODE',
  UNLIMITED_MODES = 'UNLIMITED_MODES',
  DAILY_MODES = 'DAILY_MODES',
  CORRECT_WORD_MESSAGE = 'CORRECT_WORD_MESSAGE',
  WRONG_SPOT_MESSAGE = 'WRONG_SPOT_MESSAGE',
  NOT_CONTAINED_MESSAGE = 'NOT_CONTAINED_MESSAGE',
  ENTER_TEXT = 'ENTER_TEXT',
  DELETE_TEXT = 'DELETE_TEXT',
  STATISTICS_TITLE = 'STATISTICS_TITLE',
  GUESS_DISTRIBUTION_TEXT = 'GUESS_DISTRIBUTION_TEXT',
  NEW_WORD_TEXT = 'NEW_WORD_TEXT',
  SHARE_TEXT = 'SHARE_TEXT',
  SHARE_FAILURE_TEXT = 'SHARE_FAILURE_TEXT',
  MIGRATE_BUTTON_TEXT = 'MIGRATE_BUTTON_TEXT',
  MIGRATE_DESCRIPTION_TEXT = 'MIGRATE_DESCRIPTION_TEXT',
  TOTAL_TRIES_TEXT = 'TOTAL_TRIES_TEXT',
  SUCCESS_RATE_TEXT = 'SUCCESS_RATE_TEXT',
  CURRENT_STREAK_TEXT = 'CURRENT_STREAK_TEXT',
  BEST_STREAK_TEXT = 'BEST_STREAK_TEXT',
  DISCOURAGE_INAPP_BROWSER_TEXT = 'DISCOURAGE_INAPP_BROWSER_TEXT',
  PLAY_AGAIN = 'PLAY_AGAIN',
  REMATCH = 'REMATCH',
  RESTART = 'RESTART',
  INCOMPLETE_GAME = 'INCOMPLETE_GAME',
  SWITCH_LANGUAGE = 'SWITCH_LANGUAGE',
  LANGUAGE = 'LANGUAGE',
  NEW_GAME = 'NEW_GAME',
  SETTINGS = 'SETTINGS',
  HARD_MODE = 'HARD_MODE',
  GRID_HARD_MODE = 'GRID_HARD_MODE',
  HIDDEN_LETTER_MODE = 'HIDDEN_LETTER_MODE',
  DARK_MODE = 'DARK_MODE',
  HIGH_CONTRAST_MODE = 'HIGH_CONTRAST_MODE',
  HOW_TO_PLAY = 'HOW_TO_PLAY',
  HOW_TO_PLAY_INTRO = 'HOW_TO_PLAY_INTRO',
  HOW_TO_PLAY_WORD = 'HOW_TO_PLAY_WORD',
  HOW_TO_PLAY_INTRO_2 = 'HOW_TO_PLAY_INTRO_2',
  HOW_TO_PLAY_WORD_2 = 'HOW_TO_PLAY_WORD_2',
  HOW_TO_PLAY_INTRO_3 = 'HOW_TO_PLAY_INTRO_3',
  HOW_TO_PLAY_WORD_3 = 'HOW_TO_PLAY_WORD_3',
  HOW_TO_PLAY_INTRO_4 = 'HOW_TO_PLAY_INTRO_4',
  HOW_TO_PLAY_WORD_4 = 'HOW_TO_PLAY_WORD_4',
  HOW_TO_PLAY_INTRO_5 = 'HOW_TO_PLAY_INTRO_5',
  HOW_TO_PLAY_END = 'HOW_TO_PLAY_END',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  ABOUT_US = 'ABOUT_US',
  EMAIL_US = 'EMAIL_US',
  CHECK_SPOTLE_BOT = 'CHECK_SPOTLE_BOT',
  YOUR_GAME = 'YOUR_GAME',
  BOTS_GAME = 'BOTS_GAME',
  RETURN = 'RETURN',
  STAIRCASE = 'STAIRCASE',
  LAZY = 'LAZY',
  LAZY_UNLIMITED = 'LAZY_UNLIMITED',
  GAME_MODES = 'GAME_MODES',
  CHOOSE_GAME_MODE = 'CHOOSE_GAME_MODE',
  TAYTAY = 'TAYTAY',
  TAYTAY_UNLIMITED = 'TAYTAY_UNLIMITED',
  IMPOSSIBLE_SPOTLE = 'IMPOSSIBLE_SPOTLE',
  DUMMIES = 'DUMMIES',
  SPEEDY = 'SPEEDY',
  SPEEDY_LAZY = 'SPEEDY_LAZY',
  SPEEDY_UNLIMITED = 'SPEEDY_UNLIMITED',
  SPEEDY_LAZY_UNLIMITED = 'SPEEDY_LAZY_UNLIMITED',
  LAZY_MODE_ALERT_MESSAGE = 'LAZY_MODE_ALERT_MESSAGE',
  LAZY_MODE_ALERT_MESSAGE2 = 'LAZY_MODE_ALERT_MESSAGE2',
  LAZY_FUTURE_UPDATE_MESSAGE = 'LAZY_FUTURE_UPDATE_MESSAGE',
  BOT_ASSISTED_MODE_DESCRIPTION = 'BOT_ASSISTED_MODE_DESCRIPTION',
  BOT_ASSISTED_MODE = 'BOT_ASSISTED_MODE',
  RANDOM_MODE_DESCRIPTION = 'RANDOM_MODE_DESCRIPTION',
  RANDOM_MODE = 'RANDOM_MODE',
  SPYFALL_DESCRIPTION = 'SPYFALL_DESCRIPTION',
  SPYFALL_MODE = 'SPYFALL_MODE',
  RESET = 'RESET',
  RESET_CONFIRM = 'RESET_CONFIRM',
  GAME_SETTINGS = 'GAME_SETTINGS',
  GAME_SETTINGS_DESCRIPTION = 'GAME_SETTINGS_DESCRIPTION',
  THEME_SETTINGS = 'THEME_SETTINGS',
  THEME_SETTINGS_DESCRIPTION = 'THEME_SETTINGS_DESCRIPTION',
  GAME_SETTINGS_DISCLAIMER = 'GAME_SETTINGS_DISCLAIMER',
  SPEEDY_SETTINGS = 'SPEEDY_SETTINGS',
  SPEEDY_NUM_OF_GAMES = 'SPEEDY_NUM_OF_GAMES',
  SPEEDY_NUM_OF_GAMES_DESCRIPTION = 'SPEEDY_NUM_OF_GAMES_DESCRIPTION',
  TOP_FIVE = 'TOP_FIVE',
  TIME_PENALTY = 'TIME_PENALTY',
  TIME = 'TIME',
  IMPOSSIBLE_SETTINGS = 'IMPOSSIBLE_SETTINGS',
  NUM_OF_ROWS = 'NUM_OF_ROWS',
  NUM_OF_ROWS_DESCRIPTION = 'NUM_OF_ROWS_DESCRIPTION',
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
  GAME_ANALYSIS = 'GAME_ANALYSIS',
  YOU_PLAYED = 'YOU_PLAYED',
  BOT_PLAYED = 'BOT_PLAYED',
  IDEAL_PLAYED = 'IDEAL_PLAYED',
  SKILL = 'SKILL',
  WORDS_LEFT = 'WORDS_LEFT',
  LUCK = 'LUCK',
  INVALID_USERNAME = 'INVALID_USERNAME',
  STAIRCASE_MODE_DESCRIPTION = 'STAIRCASE_MODE_DESCRIPTION',
  LAZY_MODE_DESCRIPTION = 'LAZY_MODE_DESCRIPTION',
  SWIFTIE_MODE_DESCRIPTION = 'SWIFTIE_MODE_DESCRIPTION',
  SWIFTIE_UNLIMITED_MODE_DESCRIPTION = 'SWIFTIE_UNLIMITED_MODE_DESCRIPTION',
  DUMMIES_MODE_DESCRIPTION = 'DUMMIES_MODE_DESCRIPTION',
  SPEEDY_MODE_DESCRIPTION = 'SPEEDY_MODE_DESCRIPTION',
  SPEEDY_LAZY_MODE_DESCRIPTION = 'SPEEDY_LAZY_MODE_DESCRIPTION',
  IMPOSSIBLE_MODE_DESCRIPTION = 'IMPOSSIBLE_MODE_DESCRIPTION',
  COSPOTLE_MODE_DESCRIPTION = 'COSPOTLE_MODE_DESCRIPTION',
  COSPOTLE_BOT_MODE_DESCRIPTION = 'COSPOTLE_BOT_MODE_DESCRIPTION',
  COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION = 'COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION',
  COSPOTLE_TEAM_MODE_DESCRIPTION = 'COSPOTLE_TEAM_MODE_DESCRIPTION',
  COSPOTLE_PARALLEL_MODE_DESCRIPTION = 'COSPOTLE_PARALLEL_MODE_DESCRIPTION',
  COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION = 'COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION',
  COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION = 'COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION',
  BOT_COUNTRY = 'BOT_COUNTRY',
  INVITE = 'INVITE',
  USERNAME = 'USERNAME',
  SPOTLE = 'SPOTLE',
  NORMAL = 'NORMAL',
  SPOTLE_UNLIMITED = 'SPOTLE_UNLIMITED',
  SLAVA_UKRAINI = 'SLAVA_UKRAINI',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  COOKIES_POLICY = 'COOKIES_POLICY',
  SPOTLE_FOR_DUMMIES = 'SPOTLE_FOR_DUMMIES',
  SPOTLE_BOT = 'SPOTLE_BOT',
  STAIRCASE_SPOTLE = 'STAIRCASE_SPOTLE',
  LAZY_SPOTLE = 'LAZY_SPOTLE',
  SWIFTIE_SPOTLE = 'SWIFTIE_SPOTLE',
  SWIFTIE_SPOTLE_UNLIMITED = 'SWIFTIE_SPOTLE_UNLIMITED',
  SPEEDY_SPOTLE = 'SPEEDY_SPOTLE',
  SPEEDY_LAZY_SPOTLE = 'SPEEDY_LAZY_SPOTLE',
  SCALIZZI = 'SCALIZZI',
  IMPOSSIBLE_SPOTLE2 = 'IMPOSSIBLE_SPOTLE2',
  IMPOSSIBLE_SPOTLE3 = 'IMPOSSIBLE_SPOTLE3',
  IMPOSSIBLE_SPOTLE4 = 'IMPOSSIBLE_SPOTLE4',
  IMPOSSIBLE_SPOTLE5 = 'IMPOSSIBLE_SPOTLE5',
  COSPOTLE_VS_BOT = 'COSPOTLE_VS_BOT',
  COSPOTLE_VS_BOT_CHALLENGE = 'COSPOTLE_VS_BOT_CHALLENGE',
  TEAM_COSPOTLE = 'TEAM_COSPOTLE',
  COSPOTLE = 'COSPOTLE',
  PARALLEL_COSPOTLE = 'PARALLEL_COSPOTLE',
  BOT_PARALLEL_COSPOTLE = 'BOT_PARALLEL_COSPOTLE',
  BOT_PARALLEL_COSPOTLE_CHALLENGE = 'BOT_PARALLEL_COSPOTLE_CHALLENGE',
  NAUGHTY_GAMER = 'NAUGHTY_GAMER',
  TRANSFER_STATS = 'TRANSFER_STATS',
  GAMEMODE_OF_THE_DAY = 'GAMEMODE_OF_THE_DAY',
  CUSTOM = 'CUSTOM',
  CUSTOM_DESCRIPTION = 'CUSTOM_DESCRIPTION',
  RANDOM_MASK = 'RANDOM_MASK',
  CUSTOM_DESCRIPTION_SIMPLE = 'CUSTOM_DESCRIPTION_SIMPLE',
  NEW_GAME_MODE = 'NEW_GAME_MODE',
  DRAW_MASK = 'DRAW_MASK',
  OPPONENT_0 = 'OPPONENT_0',
  OPPONENT_1 = 'OPPONENT_1',
  OPPONENT_2 = 'OPPONENT_2',
  OPPONENT_3 = 'OPPONENT_3',
  LEVEL = 'LEVEL',
  NOT_MY_TURN = 'NOT_MY_TURN',
  MY_TURN = 'MY_TURN',
  WINS_NEEDED = 'WINS_NEEDED',
  BOT_CHALLENGE_WIN = 'BOT_CHALLENGE_WIN',
  BOT_CHALLENGE_LOSS = 'BOT_CHALLENGE_LOSS',
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  SOLUTIONS_REMAINING = 'SOLUTIONS_REMAINING',
  MULTIPLAYER_MODE = 'MULTIPLAYER_MODE',
  TIE_MESSAGES = 'TIE_MESSAGES',
  GINJANNER = 'GINJANNER',
  GINJANNER_UNLIMITED = 'GINJANNER_UNLIMITED',
  GINJANNER_DESCRIPTION = 'GINJANNER_DESCRIPTION',
  GINJANNER_UNLIMITED_DESCRIPTION = 'GINJANNER_UNLIMITED_DESCRIPTION',
  GLOBAL_GUESSES = 'GLOBAL_GUESSES',
  SIX_SPOTLE = 'SIX_SPOTLE',
  FIVE_LETTER = 'FIVE_LETTER',
  SIX_LETTER = 'SIX_LETTER',
  YOUR_SETTINGS = 'YOUR_SETTINGS',
  COMBINED_SETTINGS = 'COMBINED_SETTINGS',
  SOLUTION = "SOLUTION",
  YOUR_GUESS = 'YOUR_GUESS',
}

export const MESSAGES:{[key:string]:{[key in TranslationKey]:any}} = {
  'ENGLISH': {
    GAME_TITLE: process.env.REACT_APP_GAME_NAME!,
    PLAY: "Play",
    WIN_MESSAGES: ['Great Job!', 'Awesome', 'Well done!', 'It\'s eeeeeeeasy!', 'What a GAMER!', 'Wow', 'GG'],
    GAME_COPIED_MESSAGE:'Game copied to clipboard',
    NOT_ENOUGH_LETTERS_MESSAGE: 'Not enough letters',
    WORD_NOT_FOUND_MESSAGE: 'Word not found',
    HARD_MODE_ALERT_MESSAGE: 'Hard Mode can only be enabled at the start!',
    GRID_HARD_MODE_ALERT_MESSAGE: 'Grid Hard Mode can only be enabled at the end of a game!',
    HIDDEN_LETTER_ALERT_MESSAGE: 'Hidden Letter Mode can only be enabled at the start!',
    GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE: 'Grid Hard Mode updated for the next game',
    UNLIMITED_MODE_ALERT_MESSAGE: 'Unlimited Mode can only be changed at the start or end of a game!',
    HARD_MODE_DESCRIPTION: 'Any revealed hints must be used in subsequent guesses',
    GRID_HARD_MODE_DESCRIPTION: 'Hidden blocks are only shown after each guess',
    HIDDEN_LETTER_MODE_DESCRIPTION: 'Any past hidden letters will be hidden in subsequent guesses',
    DARK_MODE_DESCRIPTION: 'Switch between light and dark theme',
    HIGH_CONTRAST_MODE_DESCRIPTION: 'For improved color vision',
    UNLIMITED_MODE_DESCRIPTION: 'Play Spotle for as long as you want',
    DAILY_MODE_DESCRIPTION: 'Play the Daily Spotle',
    UNLIMITED_MODE: 'Unlimited Mode',
    DAILY_MODE: 'Daily Mode',
    UNLIMITED_MODES: 'Unlimited Modes',
    DAILY_MODES: 'Daily Modes',
    CORRECT_WORD_MESSAGE: (solution: string) => `The word was ${solution}`,
    WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Must use ${guess} in position ${position}`,
    NOT_CONTAINED_MESSAGE: (letter: string) => `Guess must contain ${letter}`,
    ENTER_TEXT: 'Enter',
    DELETE_TEXT: 'Delete',
    STATISTICS_TITLE: 'Statistics',
    GUESS_DISTRIBUTION_TEXT: 'Guess Distribution',
    NEW_WORD_TEXT: 'New word in',
    SHARE_TEXT: 'Share',
    SHARE_FAILURE_TEXT: 'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.',
    MIGRATE_BUTTON_TEXT: 'Transfer',
    MIGRATE_DESCRIPTION_TEXT: 'Click here to transfer your statistics to a new device.',
    TOTAL_TRIES_TEXT: 'Total tries',
    SUCCESS_RATE_TEXT: 'Success rate',
    CURRENT_STREAK_TEXT: 'Current streak',
    BEST_STREAK_TEXT: 'Best streak',
    DISCOURAGE_INAPP_BROWSER_TEXT: "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser.",
    PLAY_AGAIN: 'Play again',
    REMATCH: 'Rematch',
    RESTART: 'Restart',
    INCOMPLETE_GAME: 'Please finish your current game first',
    SWITCH_LANGUAGE: 'Choose a different language',
    LANGUAGE: 'Language',
    NEW_GAME: 'New Game',
    SETTINGS: 'Settings',
    HARD_MODE: 'Hard Mode',
    GRID_HARD_MODE: 'Grid Hard Mode',
    HIDDEN_LETTER_MODE: 'Hidden Letter Mode',
    DARK_MODE: 'Dark Mode',
    HIGH_CONTRAST_MODE: 'High Contrast Mode',
    HOW_TO_PLAY:'How to play',
    HOW_TO_PLAY_INTRO:'Guess the word in 6 tries. After each guess, the color of the tiles will change to show how close your guess was to the word.',
    HOW_TO_PLAY_WORD:'TRAIL',
    HOW_TO_PLAY_INTRO_2:'The letter T is in the word and in the correct spot.',
    HOW_TO_PLAY_WORD_2:'WHALE',
    HOW_TO_PLAY_INTRO_3:'The letter H is in the word but in the wrong spot.',
    HOW_TO_PLAY_WORD_3:'FLOUR',
    HOW_TO_PLAY_INTRO_4:'The letter U is not in the word in any spot.',
    HOW_TO_PLAY_WORD_4:'TRAIN',
    HOW_TO_PLAY_INTRO_5:'The letter N may, or may not, be in the word. It\'s incognito.',
    HOW_TO_PLAY_END:'Spotle was made using the following open sourced code:',
    TERMS_OF_SERVICE:'Terms of Service',
    ABOUT_US:'About Us',
    EMAIL_US:'Any issues, questions? Email us:',
    CHECK_SPOTLE_BOT:'Compare your guesses with the Spotle Bot',
    YOUR_GAME:'Your Game',
    BOTS_GAME:'Spotle Bot\'s Game',
    RETURN:'Return',
    STAIRCASE: 'Staircase Mode',
    LAZY: 'Lazy Mode',
    GAME_MODES: 'Game Modes',
    CHOOSE_GAME_MODE: (guess: string) => `Choose your ${guess}`,
    TAYTAY: 'Taylor Swift Spotle',
    TAYTAY_UNLIMITED: 'Taylor Swift Spotle Unlimited',
    IMPOSSIBLE_SPOTLE: 'Impossible Spotle',
    DUMMIES: 'Spotle for Dummies',
    SPEEDY: 'Speedy Spotle',
    SPEEDY_LAZY: 'Speedy Lazy Spotle',
    LAZY_MODE_ALERT_MESSAGE: 'Lazy Mode can only be changed at the end of a game!',
    LAZY_MODE_ALERT_MESSAGE2: 'At least one Lazy Mode type must be active',
    LAZY_FUTURE_UPDATE_MESSAGE: 'Lazy Mode updated for the next game',
    BOT_ASSISTED_MODE_DESCRIPTION: 'Spotle Bot plays the first guesses for you',
    BOT_ASSISTED_MODE: 'Bot Assisted Mode',
    RANDOM_MODE_DESCRIPTION: 'Five words are chosen at random',
    RANDOM_MODE: 'Random Mode',
    SPYFALL_DESCRIPTION: 'Five terrible words were carefully picked for you',
    SPYFALL_MODE: 'Spyfotle Mode',
    RESET: 'Reset Stats',
    RESET_CONFIRM: 'Are you sure you want to reset the statistics? This change is irreversible!',
    GAME_SETTINGS: 'Game Settings',
    GAME_SETTINGS_DESCRIPTION: 'Specific settings of your current or future Spotle games',
    THEME_SETTINGS: 'Theme Settings',
    THEME_SETTINGS_DESCRIPTION: 'Visual/UI settings',
    GAME_SETTINGS_DISCLAIMER: 'Spotle is a hard game by default. If you use any of these settings, may the force be with you.',
    SPEEDY_SETTINGS: 'Speedy Spotle settings can only be changed at the start!',
    SPEEDY_NUM_OF_GAMES: 'Number of Rounds',
    SPEEDY_NUM_OF_GAMES_DESCRIPTION: 'Sets the number of rounds per game of Speedy Spotle',
    TOP_FIVE:'Top 5',
    TIME_PENALTY:'Time Penalty',
    TIME:'Time',
    IMPOSSIBLE_SETTINGS: 'Impossible Spotle settings can’t be changed during a game!',
    NUM_OF_ROWS: 'Number of Visible Letters',
    NUM_OF_ROWS_DESCRIPTION: 'Sets the number of visible letters per game',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    GAME_ANALYSIS: 'Game Analysis',
    YOU_PLAYED: (guess: string) => `You played ${guess}`,
    BOT_PLAYED: (guess: string) => `The Bot would have played ${guess}`,
    IDEAL_PLAYED: 'You played the ideal guess',
    SKILL: 'Skill',
    WORDS_LEFT: 'Words Left',
    LUCK: 'Luck',
    INVALID_USERNAME: 'Invalid Username',
    STAIRCASE_MODE_DESCRIPTION: 'Unlimited Spotle but the masks are staircases',
    LAZY_MODE_DESCRIPTION: 'The board is pre played with guesses picked by the bot ',
    SWIFTIE_MODE_DESCRIPTION: 'Taylor Swift related spotle',
    SWIFTIE_UNLIMITED_MODE_DESCRIPTION: 'Play Swiftie Spotle for as long as you want',
    DUMMIES_MODE_DESCRIPTION: 'Totally impossible to win Spotle',
    SPEEDY_MODE_DESCRIPTION: 'Time Attack Spotle version',
    SPEEDY_LAZY_MODE_DESCRIPTION: 'Time Attack Spotle version with pre played guesses',
    IMPOSSIBLE_MODE_DESCRIPTION: 'Spotle with a very reduced amount of visible letters',
    COSPOTLE_MODE_DESCRIPTION: 'Play Spotle against another person',
    COSPOTLE_BOT_MODE_DESCRIPTION: 'Play Spotle against the bot',
    COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION: 'Attempt to beat each bot level',
    COSPOTLE_TEAM_MODE_DESCRIPTION: 'As a team, attempt to guess the correct word',
    COSPOTLE_PARALLEL_MODE_DESCRIPTION: 'Fastest to solve the Spotle wins',
    BOT_COUNTRY: 'Mars',
    INVITE: 'Invite a friend',
    USERNAME: 'Username',
    SPOTLE: 'Spotle',
    NORMAL: 'Normal',
    SPOTLE_UNLIMITED: 'Spotle Unlimited',
    SLAVA_UKRAINI: 'Slava Ukraini',
    PRIVACY_POLICY: 'Privacy Policy',
    COOKIES_POLICY: 'Cookies Policy',
    SPOTLE_FOR_DUMMIES: 'Spotle For Dummies',
    SPOTLE_BOT: 'Spotle Bot',
    STAIRCASE_SPOTLE: 'Staircase Spotle',
    LAZY_SPOTLE: 'Lazy Spotle',
    SWIFTIE_SPOTLE: 'Swiftie Spotle',
    SWIFTIE_SPOTLE_UNLIMITED: 'Swiftie Spotle Unlimited',
    SPEEDY_SPOTLE: 'Speedy Spotle',
    SPEEDY_LAZY_SPOTLE: 'Speedy Lazy Spotle',
    SCALIZZI: 'Scalizzi',
    IMPOSSIBLE_SPOTLE2: 'Somewhat Impossible Spotle',
    IMPOSSIBLE_SPOTLE3: 'Normal Spotle',
    IMPOSSIBLE_SPOTLE4: 'Easy Spotle',
    IMPOSSIBLE_SPOTLE5: 'Normal really???',
    COSPOTLE_VS_BOT: 'CoSpotle vs Bot',
    COSPOTLE_VS_BOT_CHALLENGE: 'CoSpotle vs Bot Challenge',
    TEAM_COSPOTLE: 'Team CoSpotle',
    COSPOTLE: 'CoSpotle',
    PARALLEL_COSPOTLE: 'Parallel CoSpotle',
    NAUGHTY_GAMER: 'Naughty Gamer',
    TRANSFER_STATS: 'Transfer your statistics',
    GAMEMODE_OF_THE_DAY: 'Game Mode of the Day: ',
    CUSTOM: 'Custom Spotle',
    CUSTOM_DESCRIPTION: 'Choose the solution and share the game with a friend',
    RANDOM_MASK: 'Random Mask',
    CUSTOM_DESCRIPTION_SIMPLE: 'Choose the solution',
    NEW_GAME_MODE: 'New game mode out now!!!',
    DRAW_MASK: 'Draw your mask',
    OPPONENT_0: 'Searching for opponent',
    OPPONENT_1: 'Searching for opponent.',
    OPPONENT_2: 'Searching for opponent..',
    OPPONENT_3: 'Searching for opponent...',
    LEVEL: 'Level',
    NOT_MY_TURN: 'Not my turn',
    MY_TURN: 'My turn',
    WINS_NEEDED: 'Wins needed for next level',
    BOT_CHALLENGE_WIN: (level: number) => `Congratulations!!! You overcame level ${level}.`,
    BOT_CHALLENGE_LOSS: 'AH AH, I win, you lose!!! Drop down a level and try again!!!',
    ANNOUNCEMENTS: 'Announcements',
    LAZY_UNLIMITED: 'Lazy Mode Unlimited',
    SPEEDY_UNLIMITED: 'Speedy Spotle Unlimited',
    SPEEDY_LAZY_UNLIMITED: 'Speedy Lazy Spotle Unlimited',
    SOLUTIONS_REMAINING: 'Solutions Remaining',
    SOLUTION: 'Solution',
    MULTIPLAYER_MODE: 'Multiplayer Modes',
    BOT_PARALLEL_COSPOTLE: 'Parallel CoSpotle vs Bot',
    BOT_PARALLEL_COSPOTLE_CHALLENGE: 'Parallel CoSpotle vs Bot Challenge',
    COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION: 'Play Spotle against the bot in Parallel Mode',
    COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION: 'Beat each Level in Parallel Mode',
    TIE_MESSAGES: ['Meh!', 'You didn’t lose, but also didn’t win', '-.-'],
    GINJANNER: 'GinJanner Spotle',
    GINJANNER_UNLIMITED: ' GinJanner Spotle Unlimited',
    GINJANNER_DESCRIPTION: 'Spotle following the GinJanner rules',
    GINJANNER_UNLIMITED_DESCRIPTION: 'Unlimited GinJanner Spotle',
    GLOBAL_GUESSES: "What others did",
    SIX_SPOTLE: '6potle',
    FIVE_LETTER: 'Five Letters',
    SIX_LETTER: 'Six Letters',
    YOUR_SETTINGS: 'Players using the same game settings',
    COMBINED_SETTINGS: 'All game settings combined',
    YOUR_GUESS: 'Your guess',
  },
  'PORTUGUESE': {
    GAME_TITLE: process.env.REACT_APP_GAME_NAME!,
    PLAY: "Jogar",
    WIN_MESSAGES: ['Bom Trabalho!', 'Parabéns', 'Bem Feito!', 'Wow', 'GG'],
    GAME_COPIED_MESSAGE: 'Jogo copiado',
    NOT_ENOUGH_LETTERS_MESSAGE: 'Número de letras insuficientes',
    WORD_NOT_FOUND_MESSAGE: 'Palavra não encontrada',
    HARD_MODE_ALERT_MESSAGE: 'Hard Mode apenas pode ser ativado no início do jogo!',
    GRID_HARD_MODE_ALERT_MESSAGE: 'Grid Hard Mode apenas pode ser ativado no fim do jogo!',
    HIDDEN_LETTER_ALERT_MESSAGE: 'Hidden Letter Mode  apenas pode ser ativado no início do jogo!',
    GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE: 'Grid Hard Mode atualizado para o próximo jogo',
    UNLIMITED_MODE_ALERT_MESSAGE: 'Modo Unlimited apenas pode ser ativado no início ou fim de um jogo!',
    HARD_MODE_DESCRIPTION: 'Quaisquer ajudas reveladas tem de ser usadas nas tentativas seguintes',
    GRID_HARD_MODE_DESCRIPTION: 'Blocos escondidos apenas são mostrados após cada tentativa',
    HIDDEN_LETTER_MODE_DESCRIPTION: 'Quaisquer letras escondidas anteriomente serão escondidas nas próximas tentativas',
    DARK_MODE_DESCRIPTION: 'Tema claro ou escuro',
    HIGH_CONTRAST_MODE_DESCRIPTION: 'Maior distinção entre cores',
    UNLIMITED_MODE_DESCRIPTION: 'Jogar Spotle enquanto quiser',
    DAILY_MODE_DESCRIPTION: 'Jogar o Spotle Diário',
    UNLIMITED_MODE: 'Modo Unlimited',
    DAILY_MODE: 'Modo Diário',
    UNLIMITED_MODES: 'Modos Unlimited',
    DAILY_MODES: 'Modos Diários',
    CORRECT_WORD_MESSAGE: (solution: string) => `A palavra era ${solution}`,
    WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Tem de usar ${guess} na posição ${position}`,
    NOT_CONTAINED_MESSAGE: (letter: string) => `Tentativa tem de incluir ${letter}`,
    ENTER_TEXT: 'Enter',
    DELETE_TEXT: 'Apagar',
    STATISTICS_TITLE: 'Estatísticas',
    GUESS_DISTRIBUTION_TEXT: 'Distribuição das Tentativas',
    NEW_WORD_TEXT: 'Nova palavra em',
    SHARE_TEXT: 'Partilhar',
    SHARE_FAILURE_TEXT: 'Impossivel partilhar os resultados. Estas funcionalidade apenas está dispoivel com HTTPS em alguns browsers.',
    MIGRATE_BUTTON_TEXT: 'Transferir',
    MIGRATE_DESCRIPTION_TEXT: 'Carregue aqui para transferir as suas estatísticas para o novo dispositivo.',
    TOTAL_TRIES_TEXT: 'Total de tentativas',
    SUCCESS_RATE_TEXT: 'Taxa de sucesso',
    CURRENT_STREAK_TEXT: 'Série atual',
    BEST_STREAK_TEXT: 'Melhor série',
    DISCOURAGE_INAPP_BROWSER_TEXT: "Está a usar um embedded browser e poderá experienciar alguns problemas a partilhar ou guardar os seus resultados. Nós aconselhamos que use o browser default do seu dispositivo.",
    PLAY_AGAIN: 'Jogar novamente',
    REMATCH: 'Desforra',
    RESTART: 'Recomeçar',
    INCOMPLETE_GAME: 'Por favor acabe o jogo atual primeiro',
    SWITCH_LANGUAGE: 'Escolher um novo idioma',
    LANGUAGE: 'Idioma',
    NEW_GAME: 'Novo Jogo',
    SETTINGS: 'Definições',
    HARD_MODE: 'Modo Difícil',
    GRID_HARD_MODE: 'Modo Grelha Difícil',
    HIDDEN_LETTER_MODE: 'Modo Letra Escondida',
    DARK_MODE: 'Modo Escuro',
    HIGH_CONTRAST_MODE: 'Modo Alto Contraste',
    HOW_TO_PLAY:'Como jogar',
    HOW_TO_PLAY_INTRO:'Adivinhe a palavra em 6 tentativas. Após cada tentativa, a cor dos quadrados irá mudar de forma a mostrar quão perto está da palavra.',
    HOW_TO_PLAY_WORD:'PRATO',
    HOW_TO_PLAY_INTRO_2:'A letra P está na palavra e na posição certa.',
    HOW_TO_PLAY_WORD_2:'AVIAO',
    HOW_TO_PLAY_INTRO_3:'A letra V está na palavra mas na posição errada.',
    HOW_TO_PLAY_WORD_3:'PEIXE',
    HOW_TO_PLAY_INTRO_4:'A letra X não está na palavra.',
    HOW_TO_PLAY_WORD_4:'ROUPA',
    HOW_TO_PLAY_INTRO_5:'A letra A está, ou não, na palavra. É uma incógnita.',
    HOW_TO_PLAY_END:'O Spotle foi desenvolvido usando o seguinte código open-source:',
    TERMS_OF_SERVICE:'Termos de Serviço (em inglês)',
    ABOUT_US:'Sobre nós',
    EMAIL_US:'Algum problema? Questões? Envie-nos um email:',
    CHECK_SPOTLE_BOT:'Compare as suas tentativas com as do Spotle Bot',
    YOUR_GAME:'O seu Jogo',
    BOTS_GAME:'Jogo do Spotle Bot',
    RETURN:'Voltar',
    STAIRCASE: 'Staircase Mode',
    LAZY: 'Lazy Mode',
    GAME_MODES: 'Modos de Jogo',
    CHOOSE_GAME_MODE: (guess: string) => `Escolha o seu ${guess}`,
    TAYTAY: 'Taylor Swift Spotle',
    TAYTAY_UNLIMITED: 'Taylor Swift Spotle Unlimited',
    IMPOSSIBLE_SPOTLE: 'Spotle Impossível',
    DUMMIES: 'Spotle for Dummies',
    SPEEDY: 'Speedy Spotle',
    SPEEDY_LAZY: 'Speedy Lazy Spotle',
    LAZY_MODE_ALERT_MESSAGE: 'Lazy Mode apenas pode ser trocado no fim do jogo!',
    LAZY_MODE_ALERT_MESSAGE2: 'Pelo menos um modo de Lazy Mode tem de estar selecionado',
    LAZY_FUTURE_UPDATE_MESSAGE: 'Lazy Mode trocado para o próximo jogo',
    BOT_ASSISTED_MODE_DESCRIPTION: 'Spotle Bot joga as primeiras tentativas',
    BOT_ASSISTED_MODE: 'Modo Assistido do Bot',
    RANDOM_MODE_DESCRIPTION: 'Cinco palavras escolhidas aleatoriamente',
    RANDOM_MODE: 'Modo Aleatório',
    SPYFALL_DESCRIPTION: 'Cinco palavras horríveis escolhidas cuidadosamente',
    SPYFALL_MODE: 'Modo Spyfotle',
    RESET: 'Reset Stats',
    RESET_CONFIRM: 'Tem a certeza que pretende apagar as estatísticas? Esta ação é irreversível!',
    GAME_SETTINGS: 'Configurações do jogo',
    GAME_SETTINGS_DESCRIPTION: 'Configurações específicas do jogo atual ou futuro',
    THEME_SETTINGS: 'Configurações do tema',
    THEME_SETTINGS_DESCRIPTION: 'Configurações visuais/UI',
    GAME_SETTINGS_DISCLAIMER: 'Spotle é um jogo difícil por padrão. Se usar qualquer uma destas configurações, que a força esteja consigo.',
    SPEEDY_SETTINGS: 'As definições do Speedy Spotle apenas podem ser mudadas no início!',
    SPEEDY_NUM_OF_GAMES: 'Número de Rondas',
    SPEEDY_NUM_OF_GAMES_DESCRIPTION: 'Define o número de rondas por jogo de Speedy Spotle',
    TOP_FIVE:'Top 5',
    TIME_PENALTY:'Penalidade de Tempo',
    TIME:'Tempo',
    IMPOSSIBLE_SETTINGS: 'As definições do Impossible Spotle não podem ser mudadas durante o decorrer de um jogo!',
    NUM_OF_ROWS: 'Número de letras visiveis',
    NUM_OF_ROWS_DESCRIPTION: 'Define o número de letras visiveis por cada tentativa',
    PREVIOUS: 'Anterior',
    NEXT: 'Próxima',
    GAME_ANALYSIS: 'Análise do Jogo',
    YOU_PLAYED: (guess: string) => `Você jogou ${guess}`,
    BOT_PLAYED: (guess: string) => `O Bot teria jogado ${guess}`,
    IDEAL_PLAYED: 'Você jogou a tentativa ideal',
    SKILL: 'Skill',
    WORDS_LEFT: 'Pal. rest',
    LUCK: 'Sorte',
    INVALID_USERNAME: 'Username Inválido',
    STAIRCASE_MODE_DESCRIPTION: 'Spotle ilimitado, mas as máscaras são escadas',
    LAZY_MODE_DESCRIPTION: 'O tabuleiro é pré-jogado com palpites escolhidos pelo bot ',
    SWIFTIE_MODE_DESCRIPTION: 'Spotle relacionado a Taylor Swift',
    SWIFTIE_UNLIMITED_MODE_DESCRIPTION: 'Jogue Swiftie Spotle pelo tempo que quiser',
    DUMMIES_MODE_DESCRIPTION: 'Totalmente impossível de ganhar Spotle',
    SPEEDY_MODE_DESCRIPTION: 'Versão Time Attack',
    SPEEDY_LAZY_MODE_DESCRIPTION: 'Versão Time Attack com palpites pré-jogados',
    IMPOSSIBLE_MODE_DESCRIPTION: 'Spotle com uma quantidade muito reduzida de letras visíveis',
    COSPOTLE_MODE_DESCRIPTION: 'Jogar Spotle contra outra pessoa',
    COSPOTLE_BOT_MODE_DESCRIPTION: 'Jogar Spotle contra o bot',
    COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION: 'Tente vencer cada nivel do bot',
    COSPOTLE_TEAM_MODE_DESCRIPTION: 'Em equipa, tente adivinhar a palavra correta',
    COSPOTLE_PARALLEL_MODE_DESCRIPTION: 'O mais rápido a resolver o jogo vence',
    BOT_COUNTRY: 'Marte',
    INVITE: 'Convide um amigo',
    USERNAME: 'Username',
    NORMAL: 'Normal',
    SLAVA_UKRAINI: 'Slava Ukraini',
    SPOTLE_UNLIMITED: 'Spotle Unlimited Português',
    SPOTLE: 'Spotle Português',
    PRIVACY_POLICY: 'Privacy Policy',
    COOKIES_POLICY: 'Cookies Policy',
    SPOTLE_FOR_DUMMIES: 'Spotle For Dummies',
    SPOTLE_BOT: 'Spotle Bot',
    STAIRCASE_SPOTLE: 'Staircase Spotle Português',
    LAZY_SPOTLE: 'Lazy Spotle Português',
    SWIFTIE_SPOTLE: 'Swiftie Spotle',
    SWIFTIE_SPOTLE_UNLIMITED: 'Swiftie Spotle Unlimited',
    SPEEDY_SPOTLE: 'Speedy Spotle Português',
    SPEEDY_LAZY_SPOTLE: 'Speedy Lazy Spotle Português',
    SCALIZZI: 'Scalizzi',
    IMPOSSIBLE_SPOTLE2: 'Meio que Impossível Spotle',
    IMPOSSIBLE_SPOTLE3: 'Spotle Normal',
    IMPOSSIBLE_SPOTLE4: 'Spotle Fácil',
    IMPOSSIBLE_SPOTLE5: 'Normal???',
    COSPOTLE_VS_BOT: 'CoSpotle vs Bot Português',
    COSPOTLE_VS_BOT_CHALLENGE: 'CoSpotle vs Bot Desafio Português',
    TEAM_COSPOTLE: 'Team CoSpotle Português',
    COSPOTLE: 'CoSpotle Português',
    PARALLEL_COSPOTLE: 'Parallel CoSpotle Português',
    NAUGHTY_GAMER: 'Naughty Gamer',
    TRANSFER_STATS: 'Transfere as suas estatísticas',
    GAMEMODE_OF_THE_DAY: 'Mode de Jogo do Dia: ',
    CUSTOM: 'Custom Spotle',
    CUSTOM_DESCRIPTION: 'Escolha a solução e partilhe o jogo com um amigo',
    RANDOM_MASK: 'Máscara Aleatória',
    CUSTOM_DESCRIPTION_SIMPLE: 'Escolha a solução',
    NEW_GAME_MODE: 'Novo modo de jogo agora disponível!!!',
    DRAW_MASK: 'Desenhe a máscara',
    OPPONENT_0: 'Procurando por adversário',
    OPPONENT_1: 'Procurando por adversário.',
    OPPONENT_2: 'Procurando por adversário..',
    OPPONENT_3: 'Procurando por adversário...',
    LEVEL: 'Nível',
    NOT_MY_TURN: 'Não é a sua vez',
    MY_TURN: 'A sua vez',
    WINS_NEEDED: 'Vitórias necessárias para o próximo nível',
    BOT_CHALLENGE_WIN: (level: number) => `Parabéns!!! Superaste o nível ${level}.`,
    BOT_CHALLENGE_LOSS: 'AH AH, eu ganhei, tu perdeste!!! Desce um nível e tenta novamente!!!',
    ANNOUNCEMENTS: 'Anúncios',
    LAZY_UNLIMITED: 'Lazy Mode Unlimited Português',
    SPEEDY_UNLIMITED: 'Speedy Spotle Unlimited Português',
    SPEEDY_LAZY_UNLIMITED: 'Speedy Lazy Spotle Unlimited Português',
    SOLUTIONS_REMAINING: 'Soluções Restantes',
    SOLUTION: 'Solução',
    MULTIPLAYER_MODE: 'Modos Multiplayer',
    BOT_PARALLEL_COSPOTLE: 'CoSpotle Paralelo vs Bot',
    BOT_PARALLEL_COSPOTLE_CHALLENGE: 'CoSpotle Paralelo vs Bot',
    COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION: 'Jogue Spotle contra o bot no modo paralelo',
    COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION: 'Vença cada nível no modo paralelo',
    TIE_MESSAGES: ['Meh!', '-.-'],
    GINJANNER: 'GinJanner Spotle',
    GINJANNER_UNLIMITED: ' GinJanner Spotle Unlimited',
    GINJANNER_DESCRIPTION: '',
    GINJANNER_UNLIMITED_DESCRIPTION: '',
    GLOBAL_GUESSES: "O que os outros fizeram",
    SIX_SPOTLE: '6potle',
    FIVE_LETTER: 'Cinco Palavras',
    SIX_LETTER: 'Seis Palavras',
    YOUR_SETTINGS: 'Jogadores a utilizar as mesmas definições de jogo',
    COMBINED_SETTINGS: 'Todas as definições do jogo combinadas',
    YOUR_GUESS: 'A sua tentativa',
  },
  'FRENCH': {
    GAME_TITLE: process.env.REACT_APP_GAME_NAME!,
    PLAY: "Jouer",
    WIN_MESSAGES: ['Bon Travail!', 'Bravo', 'C’est ça!', 'Wow', 'GG'],
    GAME_COPIED_MESSAGE: 'Jeu copié',
    NOT_ENOUGH_LETTERS_MESSAGE: 'Nombre insuffisant de lettres',
    WORD_NOT_FOUND_MESSAGE: 'Mot introuvable',
    HARD_MODE_ALERT_MESSAGE: 'Le Hard Mode ne peut être activé qu’au début du jeu!',
    GRID_HARD_MODE_ALERT_MESSAGE: 'Grid Hard Mode ne peut être activé qu’à la fin du jeu!',
    HIDDEN_LETTER_ALERT_MESSAGE: 'Le Hidden Letter Mode ne peut être activé qu’au début du jeu!',
    GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE: 'Grid Hard Mode mise à jour pour le prochain jeu',
    UNLIMITED_MODE_ALERT_MESSAGE: 'Modo Unlimited ne peut être activé qu’au début ou à la fin d’une partie!',
    HARD_MODE_DESCRIPTION: 'Les aides révélées peuvent être utilisées dans les tentatives ultérieures',
    GRID_HARD_MODE_DESCRIPTION: 'Les blocs cachés ne sont affichés qu’après chaque tentative',
    HIDDEN_LETTER_MODE_DESCRIPTION: 'Toutes les lettres masquées passées seront masquées dans les suppositions ultérieures',
    DARK_MODE_DESCRIPTION: 'Thème clair ou foncé',
    HIGH_CONTRAST_MODE_DESCRIPTION: 'Une plus grande distinction entre les couleurs',
    UNLIMITED_MODE_DESCRIPTION: 'Jouez à Spotle aussi longtemps que vous le souhaitez',
    DAILY_MODE_DESCRIPTION: 'Jouez au Spotle Quotidien',
    UNLIMITED_MODE: 'Mode Unlimited',
    DAILY_MODE: 'Mode Quotidien',
    UNLIMITED_MODES: 'Modes Illimités',
    DAILY_MODES: 'Modes Quotidiens',
    CORRECT_WORD_MESSAGE: (solution: string) => `Le mot était ${solution}`,
    WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Vous devez utiliser ${guess} dans la position ${position}`,
    NOT_CONTAINED_MESSAGE: (letter: string) => `La tentative doit inclure ${letter}`,
    ENTER_TEXT: 'Enter',
    DELETE_TEXT: 'Effacer',
    STATISTICS_TITLE: 'Statistiques',
    GUESS_DISTRIBUTION_TEXT: 'Répartition des tentatives',
    NEW_WORD_TEXT: 'Nouveau mot dans',
    SHARE_TEXT: 'Partager',
    SHARE_FAILURE_TEXT: 'Il est impossible de partager les résultats. Ces fonctionnalités ne sont disponibles qu’avec HTTPS dans certains navigateurs .',
    MIGRATE_BUTTON_TEXT: 'Transfert',
    MIGRATE_DESCRIPTION_TEXT: 'Cliquez ici pour transférer vos statistiques sur votre nouvel appareil.',
    TOTAL_TRIES_TEXT: 'Nombre total de tentatives',
    SUCCESS_RATE_TEXT: 'Taux de réussite',
    CURRENT_STREAK_TEXT: 'Série actuelle',
    BEST_STREAK_TEXT: 'Meilleure série',
    DISCOURAGE_INAPP_BROWSER_TEXT: "Vous utilisez un navigateur intégré et pouvez rencontrer des problèmes de partage ou d’enregistrement de vos résultats. Nous vous conseillons d’utiliser le navigateur par défaut de votre appareil.",
    PLAY_AGAIN: 'Rejouer',
    REMATCH: 'Revanche',
    RESTART: 'Redémarrer',
    INCOMPLETE_GAME: 'Veuillez d’abord terminer le jeu en cours',
    SWITCH_LANGUAGE: 'Choisissez une nouvelle langue',
    LANGUAGE: 'Langue',
    NEW_GAME: 'Nouveau jeu',
    SETTINGS: 'Définitions',
    HARD_MODE: 'Mode Difficile',
    GRID_HARD_MODE: 'Mode Grille Dure',
    HIDDEN_LETTER_MODE: 'Mode Lettre Masquée',
    DARK_MODE: 'Mode sombre',
    HIGH_CONTRAST_MODE: 'Mode de contraste élevé',
    HOW_TO_PLAY:'Comment jouer',
    HOW_TO_PLAY_INTRO:'Devinez le mot en six tentatives. Après chaque tentative, la couleur des carrés changera de manière à montrer à quel point le mot est proche.',
    HOW_TO_PLAY_WORD:'FLEUR',
    HOW_TO_PLAY_INTRO_2:'La lettre F est dans le bon mot et la bonne position.',
    HOW_TO_PLAY_WORD_2:'AVION',
    HOW_TO_PLAY_INTRO_3:'La lettre V est dans le mot mais dans la mauvaise position.',
    HOW_TO_PLAY_WORD_3:'ECOLE',
    HOW_TO_PLAY_INTRO_4:'La lettre L n’est pas dans le mot.',
    HOW_TO_PLAY_WORD_4:'PORTE',
    HOW_TO_PLAY_INTRO_5:'La lettre E est, ou n’est pas, dans le mot. C’est inconnu.',
    HOW_TO_PLAY_END:'Spotle a été développé en utilisant le code open source suivant:',
    TERMS_OF_SERVICE:'Conditions d’utilisation',
    ABOUT_US:'Qui sommes-nous',
    EMAIL_US:'Il y a un problème? Questions? Envoyez-nous un email:',
    CHECK_SPOTLE_BOT:'Comparez vos tentatives avec le Spotle Bot',
    YOUR_GAME:'Ton Jeu',
    BOTS_GAME:'Jeu de Spotle Bot',
    RETURN:'Retourner',
    STAIRCASE: 'Staircase Mode',
    LAZY: 'Lazy Mode',
    GAME_MODES: 'Modes de jeu',
    CHOOSE_GAME_MODE: (guess: string) => `Choisissez votre ${guess}`,
    TAYTAY: 'Taylor Swift Spotle',
    TAYTAY_UNLIMITED: 'Taylor Swift Spotle Unlimited',
    IMPOSSIBLE_SPOTLE: 'Impossible Spotle',
    DUMMIES: 'Spotle for Dummies',
    SPEEDY: 'Speedy Spotle',
    SPEEDY_LAZY: 'Speedy Lazy Spotle',
    LAZY_MODE_ALERT_MESSAGE: 'Le Mode Paresseux ne peut être modifié qu’à la fin d’une partie !',
    LAZY_MODE_ALERT_MESSAGE2: 'Au moins un type de Mode Paresseux doit être activé',
    LAZY_FUTURE_UPDATE_MESSAGE: 'Mode Paresseux mis à jour pour le prochain jeu',
    BOT_ASSISTED_MODE_DESCRIPTION: 'Spotle Bot joue les premières suppositions pour vous',
    BOT_ASSISTED_MODE: 'Mode Assisté par Bot',
    RANDOM_MODE_DESCRIPTION: 'Cinq mots sont choisis au hasard',
    RANDOM_MODE: 'Mode Aléatoire',
    SPYFALL_DESCRIPTION: 'Cinq mots terribles ont été soigneusement choisis pour toi',
    SPYFALL_MODE: 'Mode Spyfotle',
    RESET: 'Reset Stats',
    RESET_CONFIRM: 'Voulez-vous vraiment effacer les statistiques ? Cette action est irréversible !',
    GAME_SETTINGS: 'Paramètres du jeu',
    GAME_SETTINGS_DESCRIPTION: 'Paramètres spécifiques de vos jeux Spotle actuels ou futurs',
    THEME_SETTINGS: "Paramètres du thème",
    THEME_SETTINGS_DESCRIPTION: 'Paramètres visuels/UI',
    GAME_SETTINGS_DISCLAIMER: 'Spotle est un jeu difficile par défaut. Si vous utilisez l’un de ces paramètres, que la force soit avec vous.',
    SPEEDY_SETTINGS: '¡La configuración de Speedy Spotle solo se puede cambiar al principio!',
    SPEEDY_NUM_OF_GAMES: "Nombre de parties",
    SPEEDY_NUM_OF_GAMES_DESCRIPTION: 'Définit le nombre de parties par partie de Speedy Spotle',
    TOP_FIVE:'Top 5',
    TIME_PENALTY:'Pénalité de Temps',
    TIME:'Temps',
    IMPOSSIBLE_SETTINGS: 'Les paramètres Spotle impossibles ne peuvent pas être modifiés au cours d’une partie !',
    NUM_OF_ROWS: 'Nombre de Lettres Visibles',
    NUM_OF_ROWS_DESCRIPTION: 'Définit le nombre de lettres visibles par jeu',
    PREVIOUS: 'Précédent',
    NEXT: 'Suivant',
    GAME_ANALYSIS: 'Analyse du jeu',
    YOU_PLAYED: (guess: string) => `Vous avez joué à ${guess}`,
    BOT_PLAYED: (guess: string) => `Le Bot aurait joué ${guess}`,
    IDEAL_PLAYED: 'Vous avez joué la supposition idéale',
    SKILL : 'Skill',
    WORDS_LEFT: 'Mots rest.',
    LUCK : 'Chance',
    INVALID_USERNAME: 'Nom d’utilisateur invalide',
    STAIRCASE_MODE_DESCRIPTION: 'Spotle illimité mais les masques sont des escaliers',
    LAZY_MODE_DESCRIPTION: 'Le tableau est pré-joué avec des suppositions choisies par le bot ',
    SWIFTIE_MODE_DESCRIPTION: 'Spotle lié à Taylor Swift',
    SWIFTIE_UNLIMITED_MODE_DESCRIPTION: "Jouez à Swiftie Spotle aussi longtemps que vous le souhaitez",
    DUMMIES_MODE_DESCRIPTION: 'Totalement impossible de gagner Spotle',
    SPEEDY_MODE_DESCRIPTION: 'Version Time Attack Spotle',
    SPEEDY_LAZY_MODE_DESCRIPTION: 'Version Time Attack Spotle avec suppositions pré-jouées',
    IMPOSSIBLE_MODE_DESCRIPTION: 'Tache avec un nombre très réduit de lettres visibles',
    COSPOTLE_MODE_DESCRIPTION: 'Jouer à Spotle contre une autre personne',
    COSPOTLE_BOT_MODE_DESCRIPTION: 'Jouez à Spotle contre le bot',
    COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION: 'Tentative de battre chaque niveau de robot',
    COSPOTLE_TEAM_MODE_DESCRIPTION: 'En équipe, essayez de deviner le mot correct',
    COSPOTLE_PARALLEL_MODE_DESCRIPTION: 'Le plus rapide à résoudre le Spotle gagne',
    BOT_COUNTRY: 'Mars',
    INVITE : 'Inviter un ami',
    USERNAME: 'Utilisateur',
    NORMAL: 'Normal',
    SLAVA_UKRAINI: 'Slava Ukraini',
    SPOTLE_UNLIMITED: 'Spotle Unlimited Français',
    SPOTLE: 'Spotle Français',
    PRIVACY_POLICY: 'Privacy Policy',
    COOKIES_POLICY: 'Cookies Policy',
    SPOTLE_FOR_DUMMIES: 'Spotle For Dummies',
    SPOTLE_BOT: 'Spotle Bot',
    STAIRCASE_SPOTLE: 'Staircase Spotle Français',
    LAZY_SPOTLE: 'Lazy Spotle Français',
    SWIFTIE_SPOTLE: 'Swiftie Spotle',
    SWIFTIE_SPOTLE_UNLIMITED: 'Swiftie Spotle Unlimited',
    SPEEDY_SPOTLE: 'Speedy Spotle Français',
    SPEEDY_LAZY_SPOTLE: 'Speedy Lazy Spotle Français',
    SCALIZZI: 'Scalizzi',
    IMPOSSIBLE_SPOTLE2: 'Somewhat Impossible Spotle',
    IMPOSSIBLE_SPOTLE3: 'Normal Spotle',
    IMPOSSIBLE_SPOTLE4: 'Easy Spotle',
    IMPOSSIBLE_SPOTLE5: 'Normal really???',
    COSPOTLE_VS_BOT: 'CoSpotle vs Bot',
    COSPOTLE_VS_BOT_CHALLENGE: 'CoSpotle vs Bot Challenge',
    TEAM_COSPOTLE: 'Team CoSpotle Français',
    COSPOTLE: 'CoSpotle Français',
    PARALLEL_COSPOTLE: 'Parallel CoSpotle Français',
    NAUGHTY_GAMER: 'Naughty Gamer',
    TRANSFER_STATS: 'Transférez vos statistiques',
    GAMEMODE_OF_THE_DAY: 'Mode de Jeu du Jour: ',
    CUSTOM: 'Custom Spotle',
    CUSTOM_DESCRIPTION: 'Choisissez la solution et partagez le jeu avec un ami',
    RANDOM_MASK: 'Masque Aléatoire',
    CUSTOM_DESCRIPTION_SIMPLE: 'Choisissez la solution',
    NEW_GAME_MODE: 'Nouveau mode de jeu disponible !!!',
    DRAW_MASK: 'Dessine ton masque',
    OPPONENT_0: 'Recherche d’adversaire',
    OPPONENT_1: 'Recherche d’adversaire.',
    OPPONENT_2: 'Recherche d’adversaire..',
    OPPONENT_3: 'Recherche d’adversaire...',
    LEVEL: 'Niveau',
    NOT_MY_TURN: 'Ce n’est pas mon tour',
    MY_TURN: 'Mon tour',
    WINS_NEEDED: "Gagnements nécessaires pour le niveau suivant",
    BOT_CHALLENGE_WIN : (level : number) => `Félicitations !!! Vous avez dépassé le niveau ${level}.`,
    BOT_CHALLENGE_LOSS: 'AH AH, je gagne, tu perds !!! Descendez d’un niveau et réessayez !!!',
    ANNOUNCEMENTS: 'Annonces',
    LAZY_UNLIMITED: 'Lazy Mode Unlimited',
    SPEEDY_UNLIMITED: 'Speedy Spotle Unlimited',
    SPEEDY_LAZY_UNLIMITED: 'Speedy Lazy Spotle Unlimited',
    SOLUTIONS_REMAINING: 'Solutions restantes',
    SOLUTION: 'Solution',
    MULTIPLAYER_MODE: 'Modes Multiplayer',
    BOT_PARALLEL_COSPOTLE: 'CoSpotle Parallel vs Bot Français',
    BOT_PARALLEL_COSPOTLE_CHALLENGE: 'CoSpotle Parallel vs Bot Français',
    COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION: 'Jouer à Spotle contre le bot en mode parallèle',
    COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION: 'Terminer chaque niveau en mode parallèle',
    TIE_MESSAGES: ['Meh!', '-.-'],
    GINJANNER: 'GinJanner Spotle',
    GINJANNER_UNLIMITED: ' GinJanner Spotle Unlimited',
    GINJANNER_DESCRIPTION: '',
    GINJANNER_UNLIMITED_DESCRIPTION: '',
    GLOBAL_GUESSES: "Ce que les autres ont fait",
    SIX_SPOTLE: '6potle',
    FIVE_LETTER: 'Cinq lettres',
    SIX_LETTER: "Six lettres",
    YOUR_SETTINGS: 'Joueurs utilisant les mêmes paramètres de jeu',
    COMBINED_SETTINGS: 'Tous les paramètres de jeu combinés',
    YOUR_GUESS : 'Votre supposition',
  },
  'SPANISH': {
    GAME_TITLE: process.env.REACT_APP_GAME_NAME!,
    PLAY: "Jugar",
    WIN_MESSAGES: ['¡Gran trabajo!', 'Impresionante', '¡Muy bien!', '¡Qué JUGADOR!', 'Wow', 'GG'],
    GAME_COPIED_MESSAGE:'Juego copiado',
    NOT_ENOUGH_LETTERS_MESSAGE: 'No hay suficientes letras',
    WORD_NOT_FOUND_MESSAGE: 'Palabra no encontrada',
    HARD_MODE_ALERT_MESSAGE: '¡El Hard Mode solo se puede habilitar al principio!',
    GRID_HARD_MODE_ALERT_MESSAGE: '¡El Grid Hard Mode solo se puede habilitar al final de un juego!',
    HIDDEN_LETTER_ALERT_MESSAGE: '¡El Hidden Letter Mode solo se puede habilitar al principio!',
    GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE: 'Grid Hard Mode actualizado para el proximo juego',
    UNLIMITED_MODE_ALERT_MESSAGE: '¡Unlimited Mode solo se puede cambiar al comienzo o al final de un juego!',
    HARD_MODE_DESCRIPTION: 'Cualquier pista revelada debe usarse en conjeturas posteriores',
    GRID_HARD_MODE_DESCRIPTION: 'Los bloques ocultos solo se muestran después de cada intento',
    HIDDEN_LETTER_MODE_DESCRIPTION: 'Cualquier letra oculta anterior se ocultará en intentos posteriores',
    DARK_MODE_DESCRIPTION: 'Cambiar entre tema claro y oscuro',
    HIGH_CONTRAST_MODE_DESCRIPTION: 'Para mejorar la visión del color',
    UNLIMITED_MODE_DESCRIPTION: 'Juega Spotle todo el tiempo que quieras',
    DAILY_MODE_DESCRIPTION: 'Juega el Spotle Diario',
    UNLIMITED_MODE: 'Unlimited Mode',
    DAILY_MODE: 'Diario Mode',
    UNLIMITED_MODES: 'Modos Ilimitados',
    DAILY_MODES: 'Modos Diarios',
    CORRECT_WORD_MESSAGE: (solution: string) => `La palabra era ${solution}`,
    WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Debe usar ${guess} en posición ${position}`,
    NOT_CONTAINED_MESSAGE: (letter: string) => `La palabra debe contener ${letter}`,
    ENTER_TEXT: 'Ingresar',
    DELETE_TEXT: 'Borrar',
    STATISTICS_TITLE: 'Estadísticas',
    GUESS_DISTRIBUTION_TEXT: 'Distribución de conjeturas',
    NEW_WORD_TEXT: 'Nueva palabra en',
    SHARE_TEXT: 'Compartir',
    SHARE_FAILURE_TEXT: 'No se pueden compartir los resultados. Esta función está disponible solo en contextos seguros (HTTPS), en algunos o en todos los navegadores compatibles.',
    MIGRATE_BUTTON_TEXT: 'Transferir',
    MIGRATE_DESCRIPTION_TEXT: 'Haga clic aquí para transferir sus estadísticas a un nuevo dispositivo.',
    TOTAL_TRIES_TEXT: 'Intentos totales',
    SUCCESS_RATE_TEXT: 'Tasa de éxito',
    CURRENT_STREAK_TEXT: 'Racha actualk',
    BEST_STREAK_TEXT: 'Mejor racha',
    DISCOURAGE_INAPP_BROWSER_TEXT: "Está utilizando un navegador integrado y puede tener problemas para compartir o guardar sus resultados. Le recomendamos que utilice el navegador predeterminado de su dispositivo.",
    PLAY_AGAIN: 'Juega de nuevo',
    REMATCH: 'Revancha',
    RESTART: 'Reiniciar',
    INCOMPLETE_GAME: 'Termina tu juego actual primero.',
    SWITCH_LANGUAGE: 'Elige otro idioma',
    LANGUAGE: 'Lenguaje',
    NEW_GAME: 'Nuevo juego',
    SETTINGS: 'Ajustes',
    HARD_MODE: 'Modo Difícil',
    GRID_HARD_MODE: 'Modo Difícil de Cuadrícula',
    HIDDEN_LETTER_MODE: 'Modo de Letras Ocultas',
    DARK_MODE: 'Modo Oscuro',
    HIGH_CONTRAST_MODE: 'Modo de Alto Contraste',
    HOW_TO_PLAY:'Cómo jugar',
    HOW_TO_PLAY_INTRO:'Adivina la palabra en 6 intentos. Después de cada suposición, el color de las fichas cambiará para mostrar qué tan cerca estuvo su suposición de la palabra.',
    HOW_TO_PLAY_WORD:'AVION',
    HOW_TO_PLAY_INTRO_2:'La letra A está en la palabra y en el lugar correcto.',
    HOW_TO_PLAY_WORD_2:'JUGAR',
    HOW_TO_PLAY_INTRO_3:'La letra U está en la palabra pero en el lugar equivocado.',
    HOW_TO_PLAY_WORD_3:'HACER',
    HOW_TO_PLAY_INTRO_4:'La letra E no está en la palabra en ningún lugar.',
    HOW_TO_PLAY_WORD_4:'PRIMO',
    HOW_TO_PLAY_INTRO_5:'La letra O puede o no estar en la palabra. Es de incógnito.',
    HOW_TO_PLAY_END:'Spotle se creó utilizando el siguiente código fuente abierto:',
    TERMS_OF_SERVICE:'Términos de servicio',
    ABOUT_US:'Sobre nosotros',
    EMAIL_US:'¿Algún problema, pregunta? Envíanos un correo electrónico:',
    CHECK_SPOTLE_BOT:'Compara tus intentos con el Spotle Bot',
    YOUR_GAME:'Tu Juego',
    BOTS_GAME:'Juego de Spotle Bot',
    RETURN:'Volver',
    STAIRCASE: 'Staircase Mode',
    LAZY: 'Lazy Mode',
    GAME_MODES: 'Modos de juego',
    CHOOSE_GAME_MODE: (guess: string) => `Elige tu ${guess}`,
    TAYTAY: 'Taylor Swift Spotle',
    TAYTAY_UNLIMITED: 'Taylor Swift Spotle Unlimited',
    IMPOSSIBLE_SPOTLE: 'Impossible Spotle Español',
    DUMMIES: 'Spotle for Dummies',
    SPEEDY: 'Speedy Spotle Español',
    SPEEDY_LAZY: 'Speedy Lazy Spotle Español',
    LAZY_MODE_ALERT_MESSAGE: '¡El Modo Perezoso solo se puede cambiar al final de un juego!',
    LAZY_MODE_ALERT_MESSAGE2: 'Al menos un tipo de Modo Perezoso debe estar activado',
    LAZY_FUTURE_UPDATE_MESSAGE: 'Modo Perezoso actualizado para el próximo juego',
    BOT_ASSISTED_MODE_DESCRIPTION: 'Spotle Bot juega las primeras conjeturas por ti',
    BOT_ASSISTED_MODE: 'Modo Asistido por Bot',
    RANDOM_MODE_DESCRIPTION: 'Se eligen cinco palabras al azar',
    RANDOM_MODE: 'Modo Aleatorio',
    SPYFALL_DESCRIPTION: 'Cinco terribles palabras fueron escogidas cuidadosamente para ti',
    SPYFALL_MODE: 'Modo Spyfotle',
    RESET: 'Reset Stats',
    RESET_CONFIRM: '¿Está seguro de que desea borrar las estadísticas? ¡Esta acción es irreversible!',
    GAME_SETTINGS: 'Configuración del juego',
    GAME_SETTINGS_DESCRIPTION: 'Configuración específica de tus juegos Spotle actuales o futuros',
    THEME_SETTINGS: 'Configuración del tema',
    THEME_SETTINGS_DESCRIPTION: 'Configuración visual/de interfaz de usuario',
    GAME_SETTINGS_DISCLAIMER: 'Spotle es un juego difícil por defecto. Si usas alguna de estas configuraciones, que la fuerza te acompañe.',
    SPEEDY_SETTINGS: '¡La configuración de Speedy Spotle solo se puede cambiar al principio!',
    SPEEDY_NUM_OF_GAMES: 'Número de rondas',
    SPEEDY_NUM_OF_GAMES_DESCRIPTION: 'Establece el número de rondas por juego de Speedy Spotle',
    TOP_FIVE:'Top 5',
    TIME_PENALTY:'Tiempo de Penalización',
    TIME:'Tiempo',
    IMPOSSIBLE_SETTINGS: '¡La configuración de Impossible Spotle no se puede cambiar durante el transcurso de un juego!',
    NUM_OF_ROWS: 'Número de Letras Visibles',
    NUM_OF_ROWS_DESCRIPTION: 'Establece el número de letras visibles por juego',
    PREVIOUS: 'Anterior',
    NEXT: 'Siguiente',
    GAME_ANALYSIS: 'Análisis del juego',
    YOU_PLAYED: (adivinar: string) => `Jugaste ${adivinar}`,
    BOT_PLAYED: (adivina: string) => `El Bot habría jugado ${adivina}`,
    IDEAL_PLAYED: 'Jugaste la conjetura ideal',
    SKILL: 'Skill',
    WORDS_LEFT: 'Pal. rest.',
    LUCK: 'Suerte',
    INVALID_USERNAME: 'Nombre de usuario no válido',
    STAIRCASE_MODE_DESCRIPTION: 'Spotle ilimitado pero las máscaras son escaleras',
    LAZY_MODE_DESCRIPTION: 'El tablero se juega previamente con conjeturas elegidas por el bot',
    SWIFTIE_MODE_DESCRIPTION: 'Spotle relacionado con Taylor Swift',
    SWIFTIE_UNLIMITED_MODE_DESCRIPTION: 'Juega a Swiftie Spotle todo el tiempo que quieras',
    DUMMIES_MODE_DESCRIPTION: 'Totalmente imposible ganar Spotle',
    SPEEDY_MODE_DESCRIPTION: 'Versión Time Attack Spotle',
    SPEEDY_LAZY_MODE_DESCRIPTION: 'Versión Time Attack Spotle con conjeturas prejugadas',
    IMPOSSIBLE_MODE_DESCRIPTION: 'Spotle con una cantidad muy reducida de letras visibles',
    COSPOTLE_MODE_DESCRIPTION: 'Juega Spotle contra otra persona',
    COSPOTLE_BOT_MODE_DESCRIPTION: 'Juega Spotle contra el bot',
    COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION: 'Intenta superar cada nivel de bot',
    COSPOTLE_TEAM_MODE_DESCRIPTION: 'En equipo, intenten adivinar la palabra correcta',
    COSPOTLE_PARALLEL_MODE_DESCRIPTION: 'El más rápido en resolver el Spotle gana',
    BOT_COUNTRY: 'Marte',
    INVITE: 'Invita a un amigo',
    USERNAME: 'Usuario',
    NORMAL: 'Normal',
    SLAVA_UKRAINI: 'Slava Ukraini',
    SPOTLE_UNLIMITED: 'Spotle Unlimited Español',
    SPOTLE: 'Spotle Español',
    PRIVACY_POLICY: 'Privacy Policy',
    COOKIES_POLICY: 'Cookies Policy',
    SPOTLE_FOR_DUMMIES: 'Spotle For Dummies',
    SPOTLE_BOT: 'Spotle Bot',
    STAIRCASE_SPOTLE: 'Staircase Spotle Español',
    LAZY_SPOTLE: 'Lazy Spotle Español',
    SWIFTIE_SPOTLE: 'Swiftie Spotle',
    SWIFTIE_SPOTLE_UNLIMITED: 'Swiftie Spotle Unlimited',
    SPEEDY_SPOTLE: 'Speedy Spotle Español',
    SPEEDY_LAZY_SPOTLE: 'Speedy Lazy Spotle Español',
    SCALIZZI: 'Scalizzi',
    IMPOSSIBLE_SPOTLE2: 'Somewhat Impossible Spotle',
    IMPOSSIBLE_SPOTLE3: 'Normal Spotle',
    IMPOSSIBLE_SPOTLE4: 'Easy Spotle',
    IMPOSSIBLE_SPOTLE5: 'Normal really???',
    COSPOTLE_VS_BOT: 'CoSpotle vs Bot',
    COSPOTLE_VS_BOT_CHALLENGE: 'CoSpotle vs Bot Challenge',
    TEAM_COSPOTLE: 'Team CoSpotle Español',
    COSPOTLE: 'CoSpotle Español',
    PARALLEL_COSPOTLE: 'Parallel CoSpotle Español',
    NAUGHTY_GAMER: 'Naughty Gamer',
    TRANSFER_STATS: 'Transfiere tus estadísticas',
    GAMEMODE_OF_THE_DAY: 'Modo de Juego del Día: ',
    CUSTOM: 'Custom Spotle',
    CUSTOM_DESCRIPTION: 'Elige la solución y comparte el juego con un amigo',
    RANDOM_MASK: 'Máscara Aleatorio',
    CUSTOM_DESCRIPTION_SIMPLE: 'Elige la solución',
    NEW_GAME_MODE: '¡¡¡Nuevo modo de juego ya disponible!!!',
    DRAW_MASK: 'Dibuja tu máscara',
    OPPONENT_0: 'Buscando oponente',
    OPPONENT_1: 'Buscando oponente.',
    OPPONENT_2: 'Buscando oponente..',
    OPPONENT_3: 'Buscando oponente...',
    LEVEL: 'Nivel',
    NOT_MY_TURN: 'No es mi turno',
    MY_TURN: 'Mi turno',
    WINS_NEEDED: 'Se necesitan victorias para el siguiente nivel',
    BOT_CHALLENGE_WIN: (level: number) => `¡¡¡Felicitaciones!!! Superaste el nivel ${level}.`,
    BOT_CHALLENGE_LOSS: '¡¡AH AH, yo gano, tú pierdes!!! ¡¡¡Baja un nivel y vuelve a intentarlo!!!',
    ANNOUNCEMENTS: 'Anuncios',
    LAZY_UNLIMITED: 'Lazy Mode Unlimited Español',
    SPEEDY_UNLIMITED: 'Speedy Spotle Unlimited Español',
    SPEEDY_LAZY_UNLIMITED: 'Speedy Lazy Spotle Unlimited Español',
    SOLUTIONS_REMAINING: 'Soluciones restantes',
    SOLUTION: 'Solución',
    MULTIPLAYER_MODE: 'Modos Multiplayer',
    BOT_PARALLEL_COSPOTLE: 'Parallel CoSpotle vs Bot Español',
    BOT_PARALLEL_COSPOTLE_CHALLENGE: 'Parallel CoSpotle vs Bot Español',
    COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION: 'Juega Spotle contra el robot en modo paralelo',
    COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION: 'Supera cada nivel en modo paralelo',
    TIE_MESSAGES: ['Meh!', '-.-'],
    GINJANNER: 'GinJanner Spotle',
    GINJANNER_UNLIMITED: ' GinJanner Spotle Unlimited',
    GINJANNER_DESCRIPTION: '',
    GINJANNER_UNLIMITED_DESCRIPTION: '',
    GLOBAL_GUESSES: "Lo que hicieron los demás",
    SIX_SPOTLE: '6potle',
    FIVE_LETTER: 'Cinco letras',
    SIX_LETTER: 'Seis letras',
    YOUR_SETTINGS: 'Jugadores que usan la misma configuración de juego',
    COMBINED_SETTINGS: 'Todas las configuraciones de juego combinadas',
    YOUR_GUESS: 'Tu suposición',
  },
  'GERMAN': {
    GAME_TITLE: process.env.REACT_APP_GAME_NAME!,
    PLAY: "Play",
    WIN_MESSAGES: ['Great Job!', 'Awesome', 'Well done!', 'It\'s eeeeeeeasy!', 'What a GAMER!', 'Wow', 'GG'],
    GAME_COPIED_MESSAGE:'Game copied to clipboard',
    NOT_ENOUGH_LETTERS_MESSAGE: 'Not enough letters',
    WORD_NOT_FOUND_MESSAGE: 'Word not found',
    HARD_MODE_ALERT_MESSAGE: 'Hard Mode can only be enabled at the start!',
    GRID_HARD_MODE_ALERT_MESSAGE: 'Grid Hard Mode can only be enabled at the end of a game!',
    HIDDEN_LETTER_ALERT_MESSAGE: 'Hidden Letter Mode can only be enabled at the start!',
    GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE: 'Grid Hard Mode updated for the next game',
    UNLIMITED_MODE_ALERT_MESSAGE: 'Unlimited Mode can only be changed at the start or end of a game!',
    HARD_MODE_DESCRIPTION: 'Any revealed hints must be used in subsequent guesses',
    GRID_HARD_MODE_DESCRIPTION: 'Hidden blocks are only shown after each guess',
    HIDDEN_LETTER_MODE_DESCRIPTION: 'Any past hidden letters will be hidden in subsequent guesses',
    DARK_MODE_DESCRIPTION: 'Switch between light and dark theme',
    HIGH_CONTRAST_MODE_DESCRIPTION: 'For improved color vision',
    UNLIMITED_MODE_DESCRIPTION: 'Play Spotle for as long as you want',
    DAILY_MODE_DESCRIPTION: 'Play the Daily Spotle',
    UNLIMITED_MODE: 'Unlimited Mode',
    DAILY_MODE: 'Daily Mode',
    UNLIMITED_MODES: 'Unlimited Modes',
    DAILY_MODES: 'Daily Modes',
    CORRECT_WORD_MESSAGE: (solution: string) => `The word was ${solution}`,
    WRONG_SPOT_MESSAGE: (guess: string, position: number) => `Must use ${guess} in position ${position}`,
    NOT_CONTAINED_MESSAGE: (letter: string) => `Guess must contain ${letter}`,
    ENTER_TEXT: 'Enter',
    DELETE_TEXT: 'Delete',
    STATISTICS_TITLE: 'Statistics',
    GUESS_DISTRIBUTION_TEXT: 'Guess Distribution',
    NEW_WORD_TEXT: 'New word in',
    SHARE_TEXT: 'Share',
    SHARE_FAILURE_TEXT: 'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.',
    MIGRATE_BUTTON_TEXT: 'Transfer',
    MIGRATE_DESCRIPTION_TEXT: 'Click here to transfer your statistics to a new device.',
    TOTAL_TRIES_TEXT: 'Total tries',
    SUCCESS_RATE_TEXT: 'Success rate',
    CURRENT_STREAK_TEXT: 'Current streak',
    BEST_STREAK_TEXT: 'Best streak',
    DISCOURAGE_INAPP_BROWSER_TEXT: "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser.",
    PLAY_AGAIN: 'Play again',
    REMATCH: 'Rematch',
    RESTART: 'Restart',
    INCOMPLETE_GAME: 'Please finish your current game first',
    SWITCH_LANGUAGE: 'Choose a different language',
    LANGUAGE: 'Language',
    NEW_GAME: 'New Game',
    SETTINGS: 'Settings',
    HARD_MODE: 'Hard Mode',
    GRID_HARD_MODE: 'Grid Hard Mode',
    HIDDEN_LETTER_MODE: 'Hidden Letter Mode',
    DARK_MODE: 'Dark Mode',
    HIGH_CONTRAST_MODE: 'High Contrast Mode',
    HOW_TO_PLAY:'How to play',
    HOW_TO_PLAY_INTRO:'Guess the word in 6 tries. After each guess, the color of the tiles will change to show how close your guess was to the word.',
    HOW_TO_PLAY_WORD:'WEARY',
    HOW_TO_PLAY_INTRO_2:'The letter W is in the word and in the correct spot.',
    HOW_TO_PLAY_WORD_2:'PILOT',
    HOW_TO_PLAY_INTRO_3:'The letter I is in the word but in the wrong spot.',
    HOW_TO_PLAY_WORD_3:'VAGUE',
    HOW_TO_PLAY_INTRO_4:'The letter U is not in the word in any spot.',
    HOW_TO_PLAY_WORD_4:'TRAIN',
    HOW_TO_PLAY_INTRO_5:'The letter N may, or may not, be in the word. It\'s incognito.',
    HOW_TO_PLAY_END:'Spotle was made using the following open sourced code:',
    TERMS_OF_SERVICE:'Terms of Service',
    ABOUT_US:'About Us',
    EMAIL_US:'Any issues, questions? Email us:',
    CHECK_SPOTLE_BOT:'Compare your guesses with the Spotle Bot',
    YOUR_GAME:'Your Game',
    BOTS_GAME:'Spotle Bot\'s Game',
    RETURN:'Return',
    STAIRCASE: 'Staircase Mode',
    LAZY: 'Lazy Mode',
    GAME_MODES: 'Game Modes',
    CHOOSE_GAME_MODE: (guess: string) => `Choose your ${guess}`,
    TAYTAY: 'Taylor Swift Spotle',
    TAYTAY_UNLIMITED: 'Taylor Swift Spotle Unlimited',
    IMPOSSIBLE_SPOTLE: 'Impossible Spotle',
    DUMMIES: 'Spotle for Dummies',
    SPEEDY: 'Speedy Spotle',
    SPEEDY_LAZY: 'Speedy Lazy Spotle',
    LAZY_MODE_ALERT_MESSAGE: 'Lazy Mode can only be changed at the end of a game!',
    LAZY_MODE_ALERT_MESSAGE2: 'At least one Lazy Mode type must be active',
    LAZY_FUTURE_UPDATE_MESSAGE: 'Lazy Mode updated for the next game',
    BOT_ASSISTED_MODE_DESCRIPTION: 'Spotle Bot plays the first guesses for you',
    BOT_ASSISTED_MODE: 'Bot Assisted Mode',
    RANDOM_MODE_DESCRIPTION: 'Five words are chosen at random',
    RANDOM_MODE: 'Random Mode',
    SPYFALL_DESCRIPTION: 'Five terrible words were carefully picked for you',
    SPYFALL_MODE: 'Spyfotle Mode',
    RESET: 'Reset Stats',
    RESET_CONFIRM: 'Are you sure you want to reset the statistics? This change is irreversible',
    GAME_SETTINGS: 'Game Settings',
    GAME_SETTINGS_DESCRIPTION: 'Specific settings of your current or future Spotle games',
    THEME_SETTINGS: 'Theme Settings',
    THEME_SETTINGS_DESCRIPTION: 'Visual/UI settings',
    GAME_SETTINGS_DISCLAIMER: 'Spotle is a hard game by default. If you use any of these settings, may the force be with you.',
    SPEEDY_SETTINGS: 'Speedy Spotle settings can only be changed at the start!',
    SPEEDY_NUM_OF_GAMES: 'Number of Rounds',
    SPEEDY_NUM_OF_GAMES_DESCRIPTION: 'Sets the number of rounds per game of Speedy Spotle',
    TOP_FIVE:'Top 5',
    TIME_PENALTY:'Time Penalty',
    TIME:'Time',
    IMPOSSIBLE_SETTINGS: 'Impossible Spotle settings can’t be changed during a game!',
    NUM_OF_ROWS: 'Number of Visible Letters',
    NUM_OF_ROWS_DESCRIPTION: 'Sets the number of visible letters per game',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    GAME_ANALYSIS: 'Game Analysis',
    YOU_PLAYED: (guess: string) => `You played ${guess}`,
    BOT_PLAYED: (guess: string) => `The Bot would have played ${guess}`,
    IDEAL_PLAYED: 'You played the ideal guess',
    SKILL: 'Skill',
    WORDS_LEFT: 'Words Left',
    LUCK: 'Luck',
    INVALID_USERNAME: 'Invalid Username',
    STAIRCASE_MODE_DESCRIPTION: 'Unlimited Spotle but the masks are staircases',
    LAZY_MODE_DESCRIPTION: 'The board is pre played with guesses picked by the bot ',
    SWIFTIE_MODE_DESCRIPTION: 'Taylor Swift related spotle',
    SWIFTIE_UNLIMITED_MODE_DESCRIPTION: 'Play Swiftie Spotle for as long as you want',
    DUMMIES_MODE_DESCRIPTION: 'Totally impossible to win Spotle',
    SPEEDY_MODE_DESCRIPTION: 'Time Attack Spotle version',
    SPEEDY_LAZY_MODE_DESCRIPTION: 'Time Attack Spotle version with pre played guesses',
    IMPOSSIBLE_MODE_DESCRIPTION: 'Spotle with a very reduced amount of visible letters',
    COSPOTLE_MODE_DESCRIPTION: 'Play Spotle against another person',
    COSPOTLE_BOT_MODE_DESCRIPTION: 'Play Spotle against the bot',
    COSPOTLE_BOT_CHALLENGE_MODE_DESCRIPTION: 'Attempt to beat each bot level',
    COSPOTLE_TEAM_MODE_DESCRIPTION: 'As a team, attempt to guess the correct word',
    COSPOTLE_PARALLEL_MODE_DESCRIPTION: 'Fastest to solve the Spotle wins',
    BOT_COUNTRY: 'Mars',
    INVITE: 'Invite a friend',
    USERNAME: 'Username',
    NORMAL: 'Normal',
    SLAVA_UKRAINI: 'Slava Ukraini',
    SPOTLE_UNLIMITED: 'Spotle Unlimited Deutsch',
    SPOTLE: 'Spotle Deutsch',
    PRIVACY_POLICY: 'Privacy Policy',
    COOKIES_POLICY: 'Cookies Policy',
    SPOTLE_FOR_DUMMIES: 'Spotle For Dummies',
    SPOTLE_BOT: 'Spotle Bot',
    STAIRCASE_SPOTLE: 'Staircase Spotle',
    LAZY_SPOTLE: 'Lazy Spotle',
    SWIFTIE_SPOTLE: 'Swiftie Spotle',
    SWIFTIE_SPOTLE_UNLIMITED: 'Swiftie Spotle Unlimited',
    SPEEDY_SPOTLE: 'Speedy Spotle',
    SPEEDY_LAZY_SPOTLE: 'Speedy Lazy Spotle',
    SCALIZZI: 'Scalizzi',
    IMPOSSIBLE_SPOTLE2: 'Somewhat Impossible Spotle',
    IMPOSSIBLE_SPOTLE3: 'Normal Spotle',
    IMPOSSIBLE_SPOTLE4: 'Easy Spotle',
    IMPOSSIBLE_SPOTLE5: 'Normal really???',
    COSPOTLE_VS_BOT: 'CoSpotle vs Bot',
    COSPOTLE_VS_BOT_CHALLENGE: 'CoSpotle vs Bot Challenge',
    TEAM_COSPOTLE: 'Team CoSpotle',
    COSPOTLE: 'CoSpotle',
    PARALLEL_COSPOTLE: 'Parallel CoSpotle',
    NAUGHTY_GAMER: 'Naughty Gamer',
    TRANSFER_STATS: 'Transfer your statistics',
    GAMEMODE_OF_THE_DAY: 'Game Mode of the Day: ',
    CUSTOM: 'Custom Spotle',
    CUSTOM_DESCRIPTION: 'Choose the solution and share the game with a friend',
    RANDOM_MASK: 'Random Mask',
    CUSTOM_DESCRIPTION_SIMPLE: 'Choose the solution',
    NEW_GAME_MODE: 'New game mode out now!!!',
    DRAW_MASK: 'Draw your mask',
    OPPONENT_0: 'Searching for opponent',
    OPPONENT_1: 'Searching for opponent.',
    OPPONENT_2: 'Searching for opponent..',
    OPPONENT_3: 'Searching for opponent...',
    LEVEL: 'Level',
    NOT_MY_TURN: 'Not my turn',
    MY_TURN: 'My turn',
    WINS_NEEDED: 'Wins needed for next level',
    BOT_CHALLENGE_WIN: (level: number) => `Congratulations!!! You overcame level ${level}.`,
    BOT_CHALLENGE_LOSS: 'AH AH, I win, you lose!!! Drop down a level and try again!!!',
    ANNOUNCEMENTS: 'Announcements',
    LAZY_UNLIMITED: 'Lazy Mode Unlimited',
    SPEEDY_UNLIMITED: 'Speedy Spotle Unlimited',
    SPEEDY_LAZY_UNLIMITED: 'Speedy Lazy Spotle Unlimited',
    SOLUTIONS_REMAINING: 'Solutions Remaining',
    SOLUTION: 'Solution',
    MULTIPLAYER_MODE: 'Multiplayer Modes',
    BOT_PARALLEL_COSPOTLE: 'Parallel CoSpotle vs Bot',
    BOT_PARALLEL_COSPOTLE_CHALLENGE: 'Parallel CoSpotle vs Bot Challenge',
    COSPOTLE_BOT_PARALLEL_MODE_DESCRIPTION: 'Play Spotle against the bot in Parallel Mode',
    COSPOTLE_BOT_PARALLEL_CHALLENGE_MODE_DESCRIPTION: 'Beat each Level in Parallel Mode',
    TIE_MESSAGES: ['Meh!', '-.-'],
    GINJANNER: 'GinJanner Spotle',
    GINJANNER_UNLIMITED: ' GinJanner Spotle Unlimited',
    GINJANNER_DESCRIPTION: '',
    GINJANNER_UNLIMITED_DESCRIPTION: '',
    GLOBAL_GUESSES: "What others did",
    SIX_SPOTLE: '6potle',
    FIVE_LETTER: 'Five Letters',
    SIX_LETTER: 'Six Letters',
    YOUR_SETTINGS: 'Players using the same game settings',
    COMBINED_SETTINGS: 'All game settings combined',
    YOUR_GUESS: 'Your guess',
  }
}