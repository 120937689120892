export function calculateSkill(
    maxScore: number,
    word_counts: Map<number, string[]>
    ): number {
    var skill = 0

    var keys: number[] = Array.from(word_counts.keys())
    keys = keys.sort((n1, n2) => n2 - n1)

    for (let idx = 0; idx < keys.length; idx++) {
        if (keys[idx] <= maxScore) {
            break
        }

        skill = skill + word_counts.get(keys[idx])!.length
    }

    const total = mapSize(word_counts)
    if (total === 0) {
      return 0
    }
    
    return Math.round(((total - skill) / total) * 100)
}

function getWordScore(
    guess: string,
    word_counts: Map<number, string[]>
  ): number {
    var keys: number[] = Array.from(word_counts.keys())
    for (let idx = 0; idx < keys.length; idx++) {
      if (word_counts.get(keys[idx])!.includes(guess.toLocaleLowerCase())) {
        return keys[idx]
      }
    }
  
    //should not happen
    return -1000
  }

export function calculateGuessSkill(
    guess: string,
    word_counts: Map<number, string[]>
    ): number {
    return calculateSkill(getWordScore(guess, word_counts), word_counts)
}

export function mapSize(word_counts: Map<number, string[]>): number {
    var total = 0

    word_counts.forEach((value: string[], _: number) => {
        total = total + value.length
    })

    return total
}

export function getPercentage(value: number, totalValue: number): number {
  return Math.round(value / totalValue * 1000) / 10
}