import { getStoredAllGlobalGuesses, getStoredDarkMode, getStoredGlobalGuesses } from "../lib/localStorage"
import { getIndex, getSolution } from "../lib/words"
import { MAX_CHALLENGES } from "../constants/settings"
import { Progress } from "../components/stats/Progress"
import { getTranslation } from "../context/messages"
import { TranslationKey } from "../constants/strings"
import { getPercentage } from "./helper"

//guess index is +1 because I was dumb and made the server that way
    const GlobalFinalStats = (props: {
        today: Date
        winIndex: number
        all?: boolean
    }) => {
        if (typeof props.today === 'undefined') {
            return <div />
        }
    
        if (getStoredDarkMode()) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        const solution = getSolution(getIndex(props.today))

        
        var valueDistribution: number[] = [];
        var totalDistribution: number[] = [];
        for(let i = 0; i < MAX_CHALLENGES; i++){
            [valueDistribution[i], totalDistribution[i]] = getNumberOfCorrectGuesses(props.all, solution, i + 1)
        }

        var total: number = 0;
        for(let subTotal of totalDistribution) {
            total = total + subTotal
        }

        var valueTotal: number = 0;
        for(let subTotal of valueDistribution) {
            valueTotal = valueTotal + subTotal
        }

        return (
            <div className="flex min-h-full items-center justify-center py-10 px-4 text-center sm:p-0">
                <div>
                    <h4 className="mt-2 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                        {getTranslation(TranslationKey.GLOBAL_GUESSES)}
                    </h4>
                    <h5 className="mt-2 text-xs font-small leading-6 text-gray-900 dark:text-gray-100">
                        {props.all && (<>{getTranslation(TranslationKey.COMBINED_SETTINGS)}</>)}
                        {!props.all && (<>{getTranslation(TranslationKey.YOUR_SETTINGS)}</>)}
                    </h5>
                    <div className="justify-left m-2 columns-1 text-m dark:text-white">
                        {valueDistribution.map((value, i) => (
                            <Progress
                                key={i}
                                index={String(i + 1)}
                                isCurrentDayStatRow={false}
                                size={90 * (value / Math.max(...valueDistribution))}
                                label={String(getPercentage(value, valueTotal) + '%')}
                            />
                        ))}
                    </div>
                    <div className="justify-left m-2 columns-1 text-m dark:text-white">
                        {totalDistribution.map((value, i) => (
                            <Progress
                                key={i}
                                index={String(i + 1)}
                                isCurrentDayStatRow={false}
                                size={90 * (value / Math.max(...totalDistribution))}
                                label={String(getPercentage(value, total) + '%')}
                            />
                        ))}
                    </div>  
                </div> 
            </div>        
        )
    }

    function getNumberOfCorrectGuesses(all: boolean | undefined, solution: string, index: number): [number, number] {
        var map = new Map()
        if(all) {
            map = getStoredAllGlobalGuesses(index)
        } else {
            map = getStoredGlobalGuesses(index)
        }

        var total = 0
        for (let key of Array.from(map.keys())) {
            total = total + map.get(key)!
        }        
        return [map.get(solution) ? map.get(solution)! : 0, total]
    }

    export default GlobalFinalStats