import { getStoredAllGlobalGuesses, getStoredDarkMode, getStoredGlobalGuesses } from "../lib/localStorage"
import { getTranslation } from "../context/messages"
import { TranslationKey } from "../constants/strings"
import { GlobalProgress } from "./GlobalProgress"
import { getIndex, getSolution } from "../lib/words"
import { getPercentage } from "./helper"

//guess index is +1 because I was dumb and made the server that way
    const GlobalStatsPanel = (props: {
        today: Date
        guessIndex: number
        playerGuess: string
        all?: boolean
        wordCounts: Map<number, string[]>
    }) => {
        const maxElements = 15
        if (typeof props.today === 'undefined') {
            return <div />
        }
    
        if (getStoredDarkMode()) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        var map = new Map()
        if(props.all) {
            map = getStoredAllGlobalGuesses(props.guessIndex)
        } else {
            map = getStoredGlobalGuesses(props.guessIndex)
        }
        
        const solution = getSolution(getIndex(props.today))

        var wordDistribution: string[];
        var valueDistribution: number[];
        var totalValue: number;
        [wordDistribution, valueDistribution, totalValue] = createWinDistribution(map)
        const guessGlobalPosition = wordDistribution.indexOf(props.playerGuess)
        wordDistribution.length = maxElements
        valueDistribution.length = maxElements    

        return (
            <div className="flex min-h-full items-center justify-center py-10 px-4 text-center sm:p-0">
                <div>
                    <h4 className="mt-2 text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">
                        {getTranslation(TranslationKey.GLOBAL_GUESSES)}
                    </h4>
                    <h5 className="mt-2 text-xs font-small leading-6 text-gray-900 dark:text-gray-100">
                        {props.all && (<>{getTranslation(TranslationKey.COMBINED_SETTINGS)}</>)}
                        {!props.all && (<>{getTranslation(TranslationKey.YOUR_SETTINGS)}</>)}
                    </h5>
                    <br></br>
                    <div className="justify-left m-2 flex dark:text-white">
                        <div style={{textAlign: "right"}} className="w-20">{getTranslation(TranslationKey.YOUR_GUESS)}</div>
                        <div className="ml-4 w-56 justify-center mr-4">
                            <div style={{width: `${8}%`, aspectRatio: '1/1' }} className={'justify-left text-xs font-medium text-blue-100 text-left p-1 bg-blue-600'}>
                            </div>
                        </div>
                    </div>
                    <div className="justify-left m-2 flex dark:text-white ">
                        <div style={{textAlign: "right"}} className="w-20">{getTranslation(TranslationKey.SOLUTION)}</div>
                        <div className="ml-4 w-56 justify-center mr-4">
                            <div style={{width: `${8}%`, aspectRatio: '1/1' }} className={'justify-left text-xs font-medium text-green-100 text-left p-1 bg-green-600'}>
                            </div>
                        </div>
                    </div>
                    <div className="justify-left m-2 columns-1 text-m dark:text-white">
                        <div className="justify-left m-1 flex font-bold">
                            <div style={{textAlign: "right"}} className="w-16">
                                {'Pos'}
                            </div>
                            <div className="ml-4 w-56 justify-center mr-4">
                                {'Title'}
                            </div>
                            <div style={{textAlign: "left"}} className="w-16">
                                {'Perc'}
                            </div>
                        </div>
                        {wordDistribution.map((word, i) => (
                            <GlobalProgress
                                key={word}
                                index={String(i + 1)}
                                isPlayerGuess={word === props.playerGuess}
                                isSolution={word === solution}
                                size={100 * (map.get(word) / valueDistribution[0])}
                                label={word}
                                percentage={getPercentage(map.get(word), totalValue) +'%'}
                            />
                        ))}
                        {guessGlobalPosition >= maxElements && (
                            <GlobalProgress
                                key={props.playerGuess}
                                index={String(guessGlobalPosition + 1)}
                                isPlayerGuess={true}
                                isSolution={props.playerGuess === solution}
                                size={100 * (map.get(props.playerGuess) / valueDistribution[0])}
                                label={props.playerGuess}
                                percentage={getPercentage(map.get(props.playerGuess), totalValue) +'%'}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
    
    function createWinDistribution(map: Map<string, number>): [string[], number[], number] {
        const wordList:string[] = []
        const valueList: number[] = []
        var total = 0

        for (let word of Array.from(map.keys())) {
            orderedInsert(word, map.get(word)!, wordList, valueList)
            total = total + map.get(word)!
        }

    return [wordList, valueList, total]
    }

    function orderedInsert(word: string, value: number, wordList: string[], valueList: number[]) {
        var i = 0
        for (i = 0; i < valueList.length; i++) {
            if(value >= valueList[i]) {
                break
            }
        }

        wordList.splice(i, 0, word);
        valueList.splice(i, 0, value);

        //we just want 10 items, so no need to fill the list with everything if we are never going to use it
    }

    export default GlobalStatsPanel