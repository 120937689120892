import { TranslationKey } from '../../constants/strings'
import { getTranslation } from '../../context/messages'
import { LogoutIcon } from '@heroicons/react/outline'

type Props = {
  handleMigrateStatsButton: () => void
}

export const MigrationIntro = ({ handleMigrateStatsButton }: Props) => {
  return (
    <div className="mt-1 columns-2 items-center items-stretch justify-center text-center dark:text-white sm:mt-1">
      <div className="mt-3 text-xs">{getTranslation(TranslationKey.MIGRATE_DESCRIPTION_TEXT)}</div>
      <button
        type="button"
        className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        onClick={handleMigrateStatsButton}
      >
        <LogoutIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
        {getTranslation(TranslationKey.MIGRATE_BUTTON_TEXT)}
      </button>
    </div>
  )
}
