import {
  BookOpenIcon,
  ChatAltIcon,
  KeyIcon,
  StopIcon,
} from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { getDefinitions, getStoredIndex } from '../../lib/localStorage';
import { TITLE_DELAY } from '../../constants/settings';
import FadeIn from 'react-fade-in/lib/FadeIn'
import { getDefinitionText } from './Dictionary';

type Props = {
  setIsContactInfoModalOnly: (value: boolean) => void
  showDefinition: boolean
  solution: string
}

export const Footbar = ({
  setIsContactInfoModalOnly, 
  showDefinition,
  solution,
}: Props) => {
  const [index, setIndex] = useState(() => {
    return getStoredIndex()
  })
  
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(getStoredIndex())
    }, TITLE_DELAY * 2)

    return () => clearInterval(interval)
  }, [])

  const definitions = getDefinitions(solution)
  const definitionIndex = index % definitions.length

  return (
    <div className="navbar">
      <hr></hr>
      <div className="navbar-content px-2 short:h-auto">
        <div className="right-icons">
          <KeyIcon
            className="mr-3 h-6 cursor-pointer dark:stroke-white"
            onClick={(event) => (window.location.href = '/privacyPolicy')}
          />
          <BookOpenIcon
            className="mr-3 h-6 cursor-pointer dark:stroke-white"
            onClick={(event) => (window.location.href = '/tos')}
          />
        </div>
        <div className="h-10 px-2">
          {definitions.map((value, i) => (
            <> 
              {(showDefinition && i === definitionIndex) && (
                <FadeIn transitionDuration={TITLE_DELAY / 3}>
                  <div className="text-xs italic dark:text-white text-center">
                    {getDefinitionText(solution, value)}
                  </div>
                </FadeIn>
              )}
            </>
          ))}
        </div>
        <div className="right-icons">
          <StopIcon
            className="mr-3 h-6 cursor-pointer dark:stroke-white"
            onClick={(event) => (window.location.href = '/cookiesPolicy')}
          />
          <ChatAltIcon
            className="mr-3 h-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsContactInfoModalOnly(true)}
          />
        </div>
      </div>
    </div>
  )
}
