import { getIndex, getSolution } from "../lib/words"
import { recordGaEvent } from "../components/alerts/Analytics"
import { getStoredDarkMode, getStoredWordCounts } from "../lib/localStorage"
import { CharStatus } from "../lib/statuses"
import { bot } from "./SpotleBot"
import { getTranslation, getTranslationWithInfo } from "../context/messages"
import { Grid } from "../components/grid/Grid"
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, PlayIcon } from "@heroicons/react/outline"
import { setShowSpotleBot } from "../App"
import { TranslationKey } from "../constants/strings"
import GlobalStatsPanel from "./GlobalStatsPanel"
import { MAX_CHALLENGES } from "../constants/settings"
import GlobalFinalStats from "./GlobalFinalStats"

const DetailedBotPlay = (props: {
    today: Date
    hardMode: boolean
    gridHardMode: boolean
    hiddenLetterMode: boolean
    playerGuesses: string[]
    playerStatuses: Map<number, CharStatus[]>
    handleBotSwitch: () => void
    botPageNumber: number
    handleIncBotPageNumber: () => void
    handleDecBotPageNumber: () => void
    onOpenSolutionsPage: Function  }) => {
    recordGaEvent('spotle_bot_' + (props.botPageNumber + 1))
  
    if (typeof props.today === 'undefined') {
      return <div />
    }
  
    if (getStoredDarkMode()) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
    

    const solution = getSolution(getIndex(props.today))
    var playerGuesses = []
    var playerStatuses = new Map<number, CharStatus[]>()
    var guesses: string[] = []
    var statuses: Map<number, CharStatus[]> = new Map<number, CharStatus[]>()
    var botStats: string[][] = []
    var wordCounts: Map<number, string[]> = new Map()
    const titles: string[] = []
    const guessIndex = Math.floor(props.botPageNumber / 2)

    if (guessIndex < props.playerGuesses.length) {
      for(var i = 0; i < guessIndex + 1; i++){
          playerGuesses.push(props.playerGuesses[i])
          playerStatuses.set(i, props.playerStatuses.get(i)!)
      }

      [guesses, statuses, botStats] = bot(
        props.hardMode!,
        props.gridHardMode!,
        props.hiddenLetterMode!,
        props.today,
        solution,
        playerGuesses,
        playerStatuses
      )

      wordCounts = getStoredWordCounts(guessIndex)

      for(let i = 0; i < botStats.length; i++){
        if(i === guessIndex){
          botStats[i][botStats[i].length] = '<'
        } else{
          botStats[i][botStats[i].length] = ''
        }
      }
    
      for (let i = 0; i < solution.length; i++) {
        titles.push('')
      }
    
      titles.push(getTranslation(TranslationKey.SKILL))
      titles.push(getTranslation(TranslationKey.WORDS_LEFT))
      titles.push(getTranslation(TranslationKey.LUCK))
      titles.push('')
    }

    const maxPageNumber = props.playerGuesses.length + MAX_CHALLENGES - 1
  
    return (
      <div className="overflow-y: scroll flex grow flex-col justify-center pb-6 short:pb-2">
        <p className="mx-0.5 flex items-center justify-center text-4xl font-bold dark:text-white short:text-2xl">
          {getTranslation(TranslationKey.GAME_ANALYSIS)}
        </p>
        <br></br>
        {(props.botPageNumber % 2 === 0 && guessIndex < props.playerGuesses.length) && (
          <>
            {props.playerGuesses[guessIndex] !== guesses[guessIndex] && (
              <>
                <p className="text-center text-md font-bold dark:text-white">
                  {getTranslationWithInfo(TranslationKey.YOU_PLAYED, props.playerGuesses[guessIndex])}
                </p>
                <p className="text-center text-md font-bold dark:text-white">
                  {getTranslationWithInfo(TranslationKey.BOT_PLAYED, guesses[guessIndex])}
                </p>
              </>
            )}
            {props.playerGuesses[guessIndex] === guesses[guessIndex] && (
              <p className="text-center text-md font-bold dark:text-white">
                {getTranslation(TranslationKey.IDEAL_PLAYED)}
              </p>
            )}
            <br></br>
            <Grid
              guesses={guesses!}
              currentGuess={''}
              isRevealing={false}
              currentRowClassName={''}
              statuses={statuses!}
              botStats={botStats}
              titles={titles}
              solution={solution}
              onOpenSolutionsPage={props.onOpenSolutionsPage}
            />
            <br></br>
            <GlobalStatsPanel today={props.today} guessIndex={guessIndex + 1} playerGuess={props.playerGuesses[guessIndex]} wordCounts={wordCounts}/>
            <br></br>
          </>
        )}
        {(props.botPageNumber % 2 !== 0 || (guessIndex >= props.playerGuesses.length && props.botPageNumber < maxPageNumber)) && (
            <GlobalStatsPanel today={props.today} guessIndex={guessIndex + 1} playerGuess={props.playerGuesses[guessIndex]} all={true} wordCounts={wordCounts}/>
        )}
        {props.botPageNumber === maxPageNumber && (
            <GlobalFinalStats today={props.today} winIndex={1} all={false}/>
        )}
        {props.botPageNumber > maxPageNumber && (
            <GlobalFinalStats today={props.today} winIndex={1} all={true}/>
        )}
        <div className="flex justify-center pb-6 short:pb-2">
          <button
            type="button"
            className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={event => {
              props.handleDecBotPageNumber()
            }}
            >
            <ArrowCircleLeftIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            {getTranslation(TranslationKey.PREVIOUS)}
          </button>
          <button
            type="button"
            className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
            onClick={() => {
              setShowSpotleBot(false)
              props.handleBotSwitch()
            }}
          >
            <PlayIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
            {getTranslation(TranslationKey.RETURN)}
          </button>
          {props.botPageNumber <= maxPageNumber && (//plus max challenges to account for the global stats + 1 for the final stats
            <button
              type="button"
              className="mt-2 mr-2 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-base"
              onClick={event => {
                props.handleIncBotPageNumber()
              }}
            >
              <ArrowCircleRightIcon className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white" />
              {getTranslation(TranslationKey.NEXT)}
            </button>
          )}
        </div>
      </div>
    )
  }
  
  export default DetailedBotPlay